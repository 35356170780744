import apiResponse  from "./ApiResponse";
import {ApiService} from "./ApiService";

export class PaymentsStore {
  constructor(private readonly api: ApiService = new ApiService()) {
  }

  public async getToken(amount: number) {
    try {
      const response = await this.api.post('/payments/token', {amount: amount})
      return apiResponse.Success<string>(response)
    } catch (e) {
      return apiResponse.HandleError(e)
    }
  }
}