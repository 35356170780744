import {ApiService} from "./ApiService";
import apiResponse  from "./ApiResponse";

export type PushNotification = {
  userId: number,
  message: string,
  title: string,
  groupId: number,
  groupName: string,
}

export class PushNotificationService {
  constructor(private readonly api: ApiService = new ApiService()) {
  }

  public async sendNotification(notification: PushNotification) {
    try {
      const response = await this.api.post('/notifications/send', notification)
      return apiResponse.Success<PushNotification>(response)
    } catch (e) {
      return apiResponse.HandleError(e)
    }
  }
}