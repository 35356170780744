import {createSlice} from "@reduxjs/toolkit";

export interface Message {
  id: number, message: string, author:string, date: string, replies: Message[]
}
export interface DashboardState {
  team: { id: number, name: string, username: string, score: number, avatarUrl: string, rank: number }[],
  messages: Message[],
  tasks: {id: number, name: string, dueDate: string, complete: boolean}[],
  trackedGoals: {id: number, label: string, name: string, value: number, color: string, goal: boolean | null}[]
}

const initialState: DashboardState = {
  team: [],
  messages: [],
  tasks: [],
  trackedGoals: []
}

const dashboardSlice= createSlice({
  name: "dashboard",
  initialState,
  reducers: {}
})

export default dashboardSlice.reducer;