/* eslint-disable react-hooks/exhaustive-deps */
import React, {ReactNode, useEffect, useState} from "react";
import {
  Breadcrumbs,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Link,
  Skeleton,
  Stack,
  Typography
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {GroupsViewCreator} from "../../view-creator/GroupsViewCreator";
import groupsViewModel, {GroupsView} from "../../views/GroupsView";
import {palette} from "../../palette";


export const Groups: React.FC = () => {
  const navigate = useNavigate();
  const onGroupClick = (groupId: number) => {
    navigate(`/dashboard/${groupId}`)
  }
  const [view, setView] = useState<GroupsView>(groupsViewModel.Empty);
  const [reload, ] = useState<boolean>(false);
  useEffect(() => {
    new GroupsViewCreator().CreateView()
                           .then(view => {
                             setView(view)
                           })
  }, [reload]);

  let card: ReactNode[];
  if (view.groups.length > 0) {

    card = view.groups.map((group) =>
            <Card key={group.id}
                  variant={"elevation"}
                  sx={{minWidth: '245px', maxWidth: '245px', cursor: 'pointer', backgroundColor: '#F6F2EC'}}
                  onMouseOver={() => {
                  }} onClick={() => {
              onGroupClick(group.id)
            }}>
              <CardActionArea focusRipple sx={{height:'100%', padding: 1}}>
                <Typography gutterBottom variant="h5" color={palette.primary.main} component="div">
                  {group.name}
                </Typography>
                <CardMedia component="img"
                           height="140"
                           image={group.groupLogo}
                           alt="Placeholder Image"/>
                <CardContent>
                  <Typography variant="body1" color="text.secondary">
                    {group.shortDescription}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
    )
  } else {
    card = ([
      <Card key={-1} variant="elevation" sx={{minWidth: '245px'}}>
        <CardActionArea sx={{height:'100%', padding: 1}}>
          <Typography gutterBottom variant="h5" component="div">
            <Skeleton variant="text" width="80%"/>
          </Typography>
          <Skeleton variant="rectangular" width="100%" height={140}/>
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              <Skeleton variant="text" width="90%"/>
              <Skeleton variant="text" width="90%"/>
              <Skeleton variant="text" width="80%"/>
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    ])
  }

  return <Container>
    <Breadcrumbs aria-label="breadcrumb" separator={'>'}>
      <Link underline="hover" color="inherit" href="/">
        Home
      </Link>

      <Typography color="text.primary">My Groups</Typography>
    </Breadcrumbs>
    <Typography gutterBottom variant="h6" component="div">
      My Groups
    </Typography>

    <Stack direction="row"
           justifyContent={{xs: 'center', sm: 'flex-start'}}
           flexShrink={0}
           alignItems="stretch"
           spacing={0}
           overflow={"scroll"}
           padding={1}
           flexWrap={"wrap"}
           gap={2}
    >
      {card}
    </Stack></Container>
}