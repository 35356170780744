import {ActivityStore}      from "../api/ActivityStore";
import {TimeframeStore}     from "../api/TimeframeStore";
import apiResponse          from "../api/ApiResponse";
import {ActivityLookupView} from "../views/ActivityLookupView";

export class ActivityLookupViewCreator {
  constructor(private readonly activityStore: ActivityStore = new ActivityStore(),
              private readonly timeframeStore: TimeframeStore = new TimeframeStore()) {
  }

  public async createView(): Promise<ActivityLookupView> {
    const [activities, timeframes] = await Promise.all([
      apiResponse.handleApiResponse(() => this.activityStore.readAll()),
      apiResponse.handleApiResponse(() => this.timeframeStore.readAll())
    ])

    return {
      activities, timeframes
    }
  }
}

