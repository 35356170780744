import * as React from 'react';
import {Fragment} from 'react';
import {Container} from '@mui/material';
import {GroupAdmin} from "../../components/Admin/GroupAdmin";

type Props = {};


export const AdminHome = (props: Props) => {


          return (
                  <Fragment>
                    <Container component={"section"}>
                      <GroupAdmin></GroupAdmin>
                    </Container>
                  </Fragment>
          );
        }
;