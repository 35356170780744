import {ApiService} from "./ApiService";
import apiResponse  from "./ApiResponse";

export class ErrorLogger {
  constructor(protected readonly api: ApiService = new ApiService()) {
  }

  public async sendLogs(error: { type: any, user: string, errorDetails: any, timestamp: string }) {
    try {
      const response = await this.api.post('/error-log', error)
      return apiResponse.Success(response)
    } catch (e) {
      return apiResponse.HandleError(e)
    }
  }
}