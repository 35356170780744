/* eslint-disable react-hooks/exhaustive-deps */
import React, {Fragment} from 'react';
import {
  Box,
  Button,
  Container,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  MobileStepper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {ArrowLeft, ArrowRight} from '@mui/icons-material';
import {QuestionView, ResponseView} from '../../views/DashboardView';
import CheckboxButton from '../../components/CheckboxButton';
import {palette} from '../../palette';
import {CreateGoalForm} from "../CreateGoalForm";
import {GoalView} from "../../views/GoalView";
import {DebugPanel} from "../DebugPanel";
import {ParticipantView} from "../../views/ParticipantView";

interface SurveyDrawerProps {
  questions: QuestionView[];
  activeStep: number;
  handleNext: () => void;
  handlePrev: () => void;
  handleFreeTextResponse: (e: any, option: ResponseView) => void;
  handleMultiSelectResponse: (e: any, option: ResponseView) => void;
  handleMutuallyExclusiveResponse: (e: any, question: QuestionView) => void;
  handleGoalResponse: (e: any, opt: ResponseView | undefined) => void;
  handleAccountabilityPartnerResponse: (e: any, option: ResponseView) => void;
  nextDisabled: boolean
  finishVisible: boolean,
  goalFormState: GoalView[],
  saveResponses: (e: any, reason: 'GOAL' | 'SURVEY_RESPONSE') => void;
  participants: ParticipantView[],
  surveyTitle: string,
}

const SurveyDrawer: React.FC<SurveyDrawerProps> = ({
                                                     surveyTitle,
                                                     questions,
                                                     activeStep,
                                                     handleNext,
                                                     handlePrev,
                                                     handleFreeTextResponse,
                                                     handleMultiSelectResponse,
                                                     handleMutuallyExclusiveResponse,
                                                     handleAccountabilityPartnerResponse,
                                                     nextDisabled,
                                                     finishVisible,
                                                     saveResponses,
                                                     handleGoalResponse,
                                                     goalFormState,
                                                     participants
                                                   }) => {

  const [saveGoal, setSaveGoal] = React.useState(false);

  return (
          <Drawer
                  elevation={16}
                  open={questions.length > 0}
                  anchor={"bottom"}
                  variant="temporary"
                  PaperProps={{
                    elevation: 24,
                    style: {borderRadius: '16px 16px 0 0', backgroundColor: 'rgba(255, 255, 255, .95)'},
                  }}
          >
            <DebugPanel displayItem={questions[activeStep]}/>
            <Box display={"flex"} sx={{backgroundColor: palette.primary.light}} component={'div'} padding={1}>
              <Grid container display={'flex'} justifyContent={"center"} flexDirection={'row'}>
                <Grid item>
                  <Typography color={palette.secondary.light} variant={"h4"}>
                    {surveyTitle}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Container maxWidth={"md"}>
              <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <Typography component={"div"} variant={"h6"}>

                </Typography>
                <Typography component={"div"} variant={"subtitle1"}
                            dangerouslySetInnerHTML={{__html: questions[activeStep].text}}>
                </Typography>
              </Box>
              <Grid container display={"flex"} flexDirection={"column"}>
                {questions[activeStep].responses.some((opt) => opt.responseType === 'MutuallyExclusive') && (
                        <RadioGroup
                                sx={{gap: 2, padding: 2}}
                                onChange={(e) => handleMutuallyExclusiveResponse(e, questions[activeStep])}
                        >
                          {questions[activeStep].responses.map((opt, optIdx) => {
                            const key = `option-${opt.id}`;
                            if (opt.responseType === 'MutuallyExclusive') {
                              return (
                                      <Grid
                                              key={key}
                                              item
                                              xs={12}
                                              flexWrap={"wrap"}
                                              alignContent="center"
                                              justifyContent={"flex-start"}
                                              flexDirection={"row"}
                                              display={"flex"}
                                      >
                                        <FormControlLabel
                                                value={opt.id}
                                                control={<Radio checked={opt.selected}/>}
                                                label={opt.text}
                                        />
                                      </Grid>
                              );
                            }
                            return null;
                          })}
                        </RadioGroup>
                )}

                {questions[activeStep].responses.some((opt) => opt.responseType === 'MultiSelect') && (
                        <Grid
                                item
                                xs={12}
                                flexWrap={"wrap"}
                                alignContent="center"
                                justifyContent={"flex-start"}
                                display={"flex"}
                                gap={2}
                        >
                          {questions[activeStep].responses.map((opt) => {
                            const key = `option-${opt.id}`;
                            if (opt.responseType === 'MultiSelect') {
                              return (
                                      <CheckboxButton
                                              checked={opt.selected}
                                              value={opt.id}
                                              key={key}
                                              label={opt.text}
                                              onChange={(e) => handleMultiSelectResponse(e, opt)}
                                      />
                              );
                            }
                            return null;
                          })}
                        </Grid>
                )}

                {questions[activeStep].responses.some((opt) => opt.responseType === 'FreeText') && (
                        <Fragment>
                          {questions[activeStep].responses.map((opt) => {
                            if (opt.responseType === 'FreeText') {
                              return (
                                      <Grid
                                              key={opt.id}
                                              item
                                              xs={12}
                                              flexWrap={"wrap"}
                                              alignContent="center"
                                              justifyContent={"flex-start"}
                                              display={"flex"}
                                              gap={2}
                                              paddingTop={2}
                                              paddingBottom={2}
                                      >
                                        <TextField
                                                sx={{minWidth: '100%'}}
                                                onChange={(e) => handleFreeTextResponse(e, opt)}

                                                label={opt.text}
                                                variant="outlined"
                                                value={opt.textResponse}
                                                fullWidth
                                        />
                                      </Grid>
                              );
                            }
                            return null;
                          })}
                        </Fragment>
                )}
                {questions[activeStep].responses.some((opt) => opt.responseType === 'Goal') && (
                        <Fragment>
                          {questions[activeStep].responses.map((opt) => {
                            if (opt.responseType === 'Goal') {
                              const formState = (questions[activeStep].id === 130) ? goalFormState[0] : goalFormState[1]
                              return (
                                      <Grid
                                              item
                                              xs={12}
                                              flexWrap={"wrap"}
                                              spacing={2}
                                              alignContent="center"
                                              justifyContent={"center"}
                                              display={"flex"}
                                              gap={2}
                                              paddingTop={2}
                                              paddingBottom={2}>

                                        <CreateGoalForm
                                                formState={formState}
                                                handleChange={(e) => {
                                                  setSaveGoal(true)
                                                  handleGoalResponse(e, opt)
                                                }}/>
                                      </Grid>
                              );
                            }
                            return null;
                          })}
                        </Fragment>
                )}
                {questions[activeStep].responses.some((opt) => opt.responseType === 'AccountabilityPartner') && (
                        <Fragment>
                          {questions[activeStep].responses.map((opt) => {
                            if (opt.responseType === 'AccountabilityPartner') {
                              return (
                                      <Grid
                                              item
                                              xs={12}
                                              flexWrap={"wrap"}
                                              spacing={2}
                                              alignContent="center"
                                              justifyContent={"center"}
                                              display={"flex"}
                                              gap={2}
                                              paddingTop={2}
                                              paddingBottom={2}>

                                        <Select onChange={(e) => handleAccountabilityPartnerResponse(e, opt)}>
                                          <MenuItem selected key={'default'} value={-1}>No Preference</MenuItem>
                                          {participants.map(participant => {
                                            return (
                                                    <MenuItem value={participant.id}
                                                              key={`participant-${participant.id}`}>{participant.name}</MenuItem>
                                            )
                                          })}
                                        </Select>
                                      </Grid>
                              );
                            }
                            return null;
                          })}
                        </Fragment>
                )}
              </Grid>
            </Container>
            <MobileStepper
                    variant={"dots"}
                    steps={questions.length}
                    position="static"
                    activeStep={activeStep}
                    backButton={
                      <IconButton size={"medium"} disabled={!(activeStep > 0)} color={"primary"} onClick={handlePrev}>
                        <ArrowLeft/> Prev
                      </IconButton>
                    }
                    nextButton={
                      <Fragment>
                        {!finishVisible &&
                                <IconButton disabled={nextDisabled} size={"medium"} color={"primary"}
                                            onClick={handleNext}>
                                  Next <ArrowRight/>
                                </IconButton>
                        }
                        {finishVisible &&
                                <Button onClick={(e) => {
                                  if (saveGoal) {
                                    saveResponses(e, 'GOAL')
                                    setSaveGoal(false)
                                  } else {
                                    saveResponses(e, 'SURVEY_RESPONSE')
                                  }

                                }} size={"large"} variant={"contained"}
                                        color={"primary"}>Finish</Button>
                        }
                      </Fragment>
                    }

            />
          </Drawer>
  );
};

export default SurveyDrawer;