import React from 'react';
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@mui/material';
import {LeaderView} from "../../views/LeaderView";
import {useUiState} from "../../utils/UiStateHook";
import {palette} from "../../palette";


interface LeaderboardProps {
  leaders: LeaderView;
  enabled?: boolean;
  disabledMessage: string;
}

const LeaderCard: React.FC<LeaderboardProps> = ({enabled = false, disabledMessage, leaders}) => {
  const uiState = useUiState(enabled)
  return (<Card variant={"elevation"} sx={{
    border: '1px solid',
    backgroundImage: `url(${enabled ?
            '//images.ctfassets.net/ihnuhnmumsts/4FAxYKETHO7y3uqBs43QLY/06d94159330aba0adb93bc15179020d5/Top_Haters_Image.png?h=250' :
            '//images.ctfassets.net/ihnuhnmumsts/1aUx75sDWPyQQxMRapiyS2/32ff4c7930f78b27a05e4f4b24ee5b17/Top_Haters_disabled.png?h=250'
    })`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top right',
    borderRadius: 4,
    height: 300
  }}>
    <CardContent>
      <Typography color={uiState.color}
                  variant="h5"
                  component="div"
                  gutterBottom>
        Top Haters</Typography>
      {!enabled &&
              <Typography color={"primary"}
                          sx={{marginTop: '10%', textShadow: `1px 1px 2px ${palette.background.paper}`, textAlign: 'center', backdropFilter: uiState.backdropFilter}}
                          variant="h5"
                          component="p"
                          gutterBottom>{disabledMessage}</Typography>}

      {enabled && <List sx={{overflow: 'scroll', height: '215px'}}>
        {Object.keys(leaders)
               .map((rank) => {
                 const leader = leaders[Number(rank)]
                 return (
                         <ListItem key={leader.participant.id}>
                           <ListItemAvatar>
                             <Avatar alt={leader.participant.name} src={leader.participant.avatarUrl}/>
                           </ListItemAvatar>
                           <ListItemText primary={leader.participant.name}/>
                           <Typography variant="body2">
                             {leader.successfulWeeks}
                           </Typography>
                         </ListItem>
                 )
               })}
      </List>}

    </CardContent>
    <CardActions></CardActions>
  </Card>);
};

export {LeaderCard};
