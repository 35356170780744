import {ContentStore}    from "../api/ContentStore";
import {DateTime}        from "luxon";
import {GroupDateMapper} from "../utils/GroupDateMapper";
// import {marked}          from "marked";

export type ScienceSomethingContent = {
  content: { title: string, body: string, offset: number, duration: number }
}

export class GetScienceSomethingViewCreator {
  constructor(private readonly content: ContentStore = new ContentStore()) {

  }

  public async CreateView(groupStart: DateTime): Promise<ScienceSomethingContent> {

    const contentItems = await this.content.readByContentType('scienceSomething', (item) => {
      return {
        title: String(item.fields['title']),
        body: String(item.fields['body']),
        offset: Number(item.fields['offset']),
        duration: Number(item.fields['duration']),
      }
    })
    const dateMapper = new GroupDateMapper(groupStart.toJSDate());
    const date = DateTime.local().toISODate()
    const currentOffset = dateMapper.day(date)

    const info =
      contentItems.find((item) => {
        return item.offset <= currentOffset && (item.offset + item.duration) >= currentOffset

      })

    return {content: info} as ScienceSomethingContent
  }
}