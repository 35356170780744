import {DateTime} from "luxon";

export const ToDateTime = (date: string | DateTime | Date): DateTime => {
  if (typeof date === "string") {
    // Strip the timezone information and interpret the date/time as local
    const dateWithoutZone = date.substring(0, 19); // Strip everything after the seconds part
    return DateTime.fromISO(dateWithoutZone, {zone: 'UTC'});
  } else if (date instanceof Date) {
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1; // JS months are 0-indexed
    const day = date.getUTCDate();

    // Manually construct the DateTime object and add the timezone offset to adjust it
    return DateTime.utc(year, month, day);
  } else if (date instanceof DateTime) {
    // If it's already a Luxon DateTime, just return it
    return date;
  }

  throw new Error('Invalid date type');
};

export const MAX_DATE = DateTime.fromISO('9999-12-31')