import * as twilio  from '@twilio/conversations'
import {ApiService} from "./ApiService";
import apiResponse  from "./ApiResponse";

export class ChatStore {

  constructor(private readonly api: ApiService = new ApiService()) {
  }

  public async CreateChatToken(email: string) {
    try {
      const response =
        await this.api.post('/MessageToken', {identity: email})
      return apiResponse.Success(response);
    } catch (e) {
      return apiResponse.HandleError(e);
    }

  }

  public async CreateChat(conversation: twilio.Conversation) {
    try {
      const response = await this.api.post('/chat', {
        sid: conversation.sid,
        name: conversation.friendlyName
      })
      return apiResponse.Success(response);
    } catch (e) {
      return apiResponse.HandleError(e);
    }
  }
}

