import {ParticipantView} from "./ParticipantView";
import {DateTime}        from "luxon";

export interface GroupView {
  pledge: number | undefined;
  chatSid: string;
  chatId: number,
  participants: ParticipantView[],
  cohortUserId: number
  id: number,
  name: string,
  shortDescription: string,
  userCount: number,
  startDate: DateTime | string,
  groupLogo: string,
  pledgeReceived: boolean
}

const empty: GroupView = {
  pledge: undefined,
  chatSid: '',
  participants: [] as ParticipantView[],
  chatId: 0,
  cohortUserId: 0,
  id: 0,
  name: '',
  shortDescription: '',
  userCount: 12,
  startDate: DateTime.now()
                     .plus({month: 1}),
  groupLogo: '',
  pledgeReceived: false
}

const defaultExport = {Empty: empty}
export default defaultExport