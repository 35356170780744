// src/components/RoleBasedAccess.tsx
import React, {ReactNode, useContext} from 'react';
import {UserRoleContext} from './UserRoleProvider';
import {roleMap} from "../../utils/RoleUtils";


interface RoleBasedAccessProps {
  requiredRoles: string[];
  children: ReactNode;
}

export const RoleBasedAccess: React.FC<RoleBasedAccessProps> = ({requiredRoles, children}) => {
  const userRole = useContext(UserRoleContext);

  // Convert role strings to bit values
  const requiredRoleBits = roleMap(requiredRoles);

  // Check if user has the required role using bitwise AND
  let hasAccess = false
  if ((userRole & requiredRoleBits) === requiredRoleBits) {
    hasAccess = true;
  }
  if (requiredRoles.length === 0) {
    hasAccess = false;
  }

  return hasAccess ? <>{children}</> : null;
};
