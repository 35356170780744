// @flow
import * as React from 'react';
import {Button, Typography} from "@mui/material";
import {KeyboardArrowLeft} from "@mui/icons-material";

type Props = {};
export const ExerciseLog: React.FC<Props> = (props: Props) => {
  return (<div>
    <Typography variant={"h2"}>Record Exercise</Typography>
    <Button href={'/Dashboard'}> <KeyboardArrowLeft/> Back</Button>
  </div>);
};