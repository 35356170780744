import {ActivityTrackingStore} from "../api/ActivityTrackingStore";
import {RecordActivityView}    from "../views/RecordActivityView";
import ActionResponse          from "./ActionResponse";
import apiResponse             from "../api/ApiResponse";

export class AddActivityAction {
  constructor(private readonly store: ActivityTrackingStore = new ActivityTrackingStore()) {
  }

  public async Execute(activityView: RecordActivityView) {
    try {
      await apiResponse.handleApiResponse(() => this.store.write(activityView));
      return ActionResponse.Success('saved activity')
    } catch (e) {
      return ActionResponse.HandleError(e)
    }
  }
}