import React, {createContext, PropsWithChildren, useContext, useEffect, useState} from 'react';
import {Client} from '@twilio/conversations';
import {useAppSelector} from "./hooks";
import logger from "./utils/logger";

// Create a context for the Twilio client and its loading state
const TwilioClientContext = createContext<{ client: Client | null; loading: boolean }>({client: null, loading: true});

interface TwilioClientProviderProps extends PropsWithChildren {
}

// Provider component
export const TwilioClientProvider: React.FC<TwilioClientProviderProps> = ({children}) => {
  const [client, setClient] = useState<Client | null>(null);
  const [loading, setLoading] = useState(true);
  const tokenState = useAppSelector(state => state.token);

  useEffect(() => {
    if (tokenState.token) {
      logger.debug('Initializing Twilio Client with token:', tokenState.token);
      const clientInstance = new Client(tokenState.token);

      clientInstance.on('connectionStateChanged', (state) => {
        logger.debug('Connection state changed:', state);
        if (state === 'connected') {

          setClient(clientInstance);
          logger.debug('Connection state changed...setting loading to FALSE', state);
          setLoading(false);
        } else {
          logger.debug('Connection state changed...setting loading to TRUE', state);
          setLoading(true);
        }
      });

      clientInstance.on('stateChanged', (state) => {
        logger.debug('State changed:', state);
      });

      return () => {
        logger.debug('Cleaning up event listeners');
        clientInstance.removeAllListeners('connectionStateChanged');
        clientInstance.removeAllListeners('stateChanged');
      };
    }
  }, [tokenState.token]);

  return (
          <TwilioClientContext.Provider value={{client, loading}}>
            {children}
          </TwilioClientContext.Provider>
  );
};

// Custom hook to use the Twilio client
export const useTwilioClient = () => {
  const context = useContext(TwilioClientContext);
  if (context === undefined) {
    throw new Error('useTwilioClient must be used within a TwilioClientProvider');
  }
  return context;
};
