// src/components/UserRoleProvider.tsx

import React, {createContext, ReactNode} from 'react';

// Create a type for user roles
type UserRole = number;

// Create a context for the user's roles
const UserRoleContext = createContext<UserRole>(0);

interface UserRoleProviderProps {
  role: UserRole;
  children: ReactNode;
}

// Provider component to wrap the application or part of it
export const UserRoleProvider: React.FC<UserRoleProviderProps> = ({role, children}) => {
  return (
          <UserRoleContext.Provider value={role}>
            {children}
          </UserRoleContext.Provider>
  );
};

export {UserRoleContext};
