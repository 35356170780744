import React, {FC, useState} from 'react';
import {Button} from '@mui/material';
import {Check} from '@mui/icons-material';

interface CheckboxButtonProps {
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: any,
  checked: boolean
}

const CheckboxButton: FC<CheckboxButtonProps> = ({checked, value, label, onChange}) => {
  const [selected, setSelected] = useState(checked);

  const handleClick = () => {
    const newSelected = !selected;
    setSelected(newSelected);
    if (onChange) {
      const event = {
        target: {
          checked: newSelected,
          value: value,
        },
      } as React.ChangeEvent<HTMLInputElement>;
      onChange(event);
    }
  };

  return (
          <Button variant={selected ? 'contained' : 'outlined'}
                  color={selected ? 'primary' : 'inherit'}
                  startIcon={selected ? <Check/> : null}
                  onClick={handleClick}
                  sx={{
                    minWidth: '100%',
                    backgroundColor: selected ? 'primary.main' : 'transparent',
                    color: selected ? 'white' : 'primary.main',
                    borderColor: selected ? 'primary.main' : 'default',
                  }}>
            {label}
          </Button>

  );
};

export default CheckboxButton;
