import participantView, {ParticipantView} from "./ParticipantView";

export interface TeamView {
  participantCount: number,
  participants: ParticipantView[]
}

const empty: TeamView = {
  participantCount: 0,
  participants: [participantView.Empty]
}

const defaultExport = {Empty: empty}
export default defaultExport;