import React, {PropsWithChildren, useEffect, useState} from "react";
import {
  Box,
  FormControl,
  Grid2,
  Grid2 as Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip
} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {GoalView} from "../views/GoalView";
import {ActivityLookupViewCreator} from "../view-creator/ActivityLookupViewCreator";

import {Lookup} from "../views/Lookup";
import {Help} from "@mui/icons-material";

export interface CreateGoalFormProps extends PropsWithChildren {
  formState: GoalView
  handleChange: (event: any) => void
}

export const CreateGoalForm: React.FC<CreateGoalFormProps> = ({formState, handleChange}) => {
  const [activities, setActivities] = useState<Lookup[]>([] as Lookup[]);
  useEffect(() => {
    new ActivityLookupViewCreator().createView()
                                   .then((view) => {
                                     const {activities} = view;
                                     setActivities(activities)
                                   })

  }, []);

  return <Box display={"flex"} flexDirection={"column"} sx={{gap: 2,}}>
    {/*<Typography variant={"body1"}>{formState.name}</Typography>*/}

    <FormControl>
      <InputLabel id="activity">Activity</InputLabel>
      <Select label={"Activity"}
              name={"activity"}
              value={formState.activity}
              size={"medium"}
              variant={"outlined"}
              labelId={"activity"}
              onChange={handleChange}
              // onChange={onActivityChange}
      >
        <MenuItem value={-1}>Select an Activity</MenuItem>
        {activities.map(a => {
          const key = `activity-${a.id}`
          return (<MenuItem key={key} value={a.id}>{a.name}</MenuItem>)
        })}
      </Select>
    </FormControl>

    <Grid container display={"flex"} flexDirection={"row"} gap={1}>

      <FormControl sx={{width: 50}}>
        <TextField onChange={handleChange}
                   value={formState.frequency}
                   name={"frequency"}
                   onFocus={(e) => {
                     e.target.select()
                   }}
                // onChange={onFrequencyChange}
        />

      </FormControl>

      <FormControl sx={{width: "79%"}}>
        <InputLabel id="timeframe">Frequency</InputLabel>
        <Select defaultValue={1}
                label={"Timeframe"}
                name={"timeframe"}
                value={formState.timeframe}
                size={"medium"}
                variant={"outlined"}
                labelId={"timeframe"}
                // onChange={onFrequencyTimeframeChange}>
                onChange={handleChange}>
          <MenuItem value={-1}>Select a Frequency</MenuItem>
          <MenuItem key={1} value={1}>{"Times Per Week"}</MenuItem>
        </Select>
      </FormControl>
      <Tooltip enterTouchDelay={300} title="Select a frequency. This is usually X times per week" arrow>
        <IconButton>
          <Help/>
        </IconButton>
      </Tooltip>
    </Grid>

    <Grid container display={"flex"} flexDirection={"row"} gap={1}>
      <FormControl sx={{width: 50}}>
        <TextField onFocus={(e) => {
          e.target.select()
        }}
                   name={"duration"}
                   value={formState.duration}
                   onChange={handleChange}
        ></TextField>
      </FormControl>
      <FormControl sx={{width: "79%"}}>
        <InputLabel id="timeframe">Duration</InputLabel>
        <Select defaultValue={2}
                name={"durationTimeframe"}
                value={formState.durationTimeframe}
                label={"Timeframe"}
                size={"medium"}
                variant={"outlined"}
                labelId={"timeframe"}
                onChange={handleChange}>
          <MenuItem value={-1}>Select a Timeframe</MenuItem>
          <MenuItem key={2} value={2}>{"Minutes"}</MenuItem>
        </Select>
      </FormControl>
      <Tooltip enterTouchDelay={300} arrow
               title="Select a duration. This is usually Y minutes per time.">
        <IconButton>
          <Help/>
        </IconButton>
      </Tooltip>
    </Grid>
    <FormControl fullWidth>
      <DatePicker disabled={!formState.datesEnabled}
                  label={"Start Date"}
                  name={"startDate"}
                  disablePast={true}
                  value={formState.startDate}
                  closeOnSelect={true}
                  onChange={(newDate) => {
                    handleChange({target: {name: 'startDate', value: newDate}})
                  }}
      />
    </FormControl>
    <FormControl fullWidth>
      <DatePicker disabled={!formState.datesEnabled} label={"End Date"}
                  name={"endDate"}
                  value={formState.endDate}
                  disablePast={true}
                  closeOnSelect={true}
                  onChange={(newDate) => {
                    handleChange({target: {name: 'endDate', value: newDate}})
                  }}
      />
    </FormControl>
    <FormControl fullWidth>
      <Grid2 container justifyContent={"flex-start"} alignItems={"center"} flexDirection={"row"} flex={"1 1 100%"}
             gap={2}>
        <TextField name={"alternate"} sx={{display: "flex", width: "90%"}} label={"Alternative Goal"}
                   onChange={handleChange} onFocus={(e) => {e.target.select()}} value={formState.alternate}></TextField>
        <Tooltip enterTouchDelay={300}
                 title="This is an alternative to your goal. For example, if your goal is to run 3 times a week, but it snows, this can be a way to do explain that you'll be skiing instead.">
          <Help></Help>
        </Tooltip>
      </Grid2>
    </FormControl>
  </Box>;
}