import {ApiService} from "./ApiService";
import apiResponse  from "./ApiResponse";

export type CohortUserProfile = {
  userId: number,
  cohortUserId: number,
  avatarUrl: string,
  email: string,
}

export class CohortUserProfileStore {
  constructor(private readonly api: ApiService = new ApiService()) {
  }

  public async readByGroupId(groupId: number) {
    try {
      const response = await this.api.get(`/Cohort/${groupId}/userProfiles`)
      return apiResponse.Success(response)
    } catch (e) {
      return apiResponse.HandleError(e)
    }
  }
}