import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import App from "./App";
import {Provider} from "react-redux";
import {store} from "./store";
import {CookiesProvider} from "react-cookie";
import {AdapterLuxon} from "@mui/x-date-pickers/AdapterLuxon";
import {LocalizationProvider} from '@mui/x-date-pickers';
import logger from "./utils/logger";
import {ErrorLoggerProvider} from "./utils/LoggingProvider";


if ('serviceWorker' in navigator && 'PushManager' in window) {
  navigator.serviceWorker.register('/sw.js')
           .then(registration => {
             logger.log('Service Worker registered with scope:', registration.scope);
           })
           .catch(error => {
             logger.error('Service Worker registration failed:', error);
           });
}
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
        <React.StrictMode>
          <CookiesProvider>
            <Provider store={store}>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <ErrorLoggerProvider>
                  <App></App>
                </ErrorLoggerProvider>
              </LocalizationProvider>
            </Provider>
          </CookiesProvider>
        </React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
