import {DateTime}                       from "luxon";
import apiResponse                      from "../api/ApiResponse";
import actionResponse, {ActionResponse} from "./ActionResponse";
import {CohortSurveyStore}              from "../api/CohortSurveyStore";
import logger                           from "../utils/logger";

export class SaveGroupSurveyAction {
  constructor(private readonly store: CohortSurveyStore = new CohortSurveyStore()) {
  }

  public async Execute(items: {
    surveyId: number,
    sectionId: number,
    cohortId: number,
    availableDate: DateTime
  }[]): Promise<ActionResponse> {
    const cohortAndSurveyId = items.reduce((acc, item) => {
      acc.surveyId = item.surveyId
      acc.cohortId = item.cohortId
      return acc
    }, {surveyId: 0, cohortId: 0})
    try {
      items.forEach((item) => {
        logger.debug(item.availableDate.toSQLDate())
      })
      const response = await apiResponse.handleApiResponse(() =>
        this.store.Write(
          cohortAndSurveyId.cohortId,
          cohortAndSurveyId.surveyId,
          [...items]))
      return actionResponse.Success(response)
    } catch (err) {
      return actionResponse.HandleError(err)
    }
  }
}