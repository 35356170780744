import React from "react";
import {
  Avatar,
  Box,
  Checkbox,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  TextField,
  Typography
} from "@mui/material";
import {palette} from "../../palette";
import {AddCircle, Delete} from "@mui/icons-material";
import {ParticipantView} from "../../views/ParticipantView";
import {RoleStrings} from "../../enums/Role";


interface ParticipantEditorProps {
  userCount: number,
  participantViews: ParticipantView[],
  onAddParticipant: (e: any) => void,
  onRemoveParticipant: React.MouseEventHandler<HTMLButtonElement>,
  onRoleChange: (e: any, participant: ParticipantView) => void
}

export const ParticipantEditor: React.FC<ParticipantEditorProps> =
        ({
           userCount,
           participantViews,
           onAddParticipant,
           onRemoveParticipant,
           onRoleChange
         }) => {

          return (<DialogContent sx={{display: "flex", flexDirection: "column", gap: 1, overflow: "scroll"}}>
            <Box display={"flex"} padding={1}>
              <TextField disabled={(userCount < participantViews.length)}
                         error={(userCount < participantViews.length)}
                         onFocus={(e) => e.target.select() }
                         fullWidth
                         type={"email"}
                         label={"Enter email address"}
                         helperText={"enter a valid email address or multiple addresses separated by ';'"}
                         onKeyUp={onAddParticipant}>

              </TextField>
              <IconButton onClick={onAddParticipant}>
                <AddCircle/>
              </IconButton>
            </Box>

            <List>

              {participantViews.map(p => {
                return (

                        <ListItem key={p.email}>
                          <Grid container gap={2} display={'flex'} justifyContent={"space-between"}>
                            <Grid display={'flex'} flexWrap={"nowrap"} item sm={3} md={5} lg={7} xl={8}
                                  flexDirection={"row"} justifyContent={"start"}
                                  alignItems={"center"} gap={1} alignContent={"center"}>
                              <Avatar component={"div"} color={palette.primary.main}></Avatar>
                              <Typography component={"div"}>{p.email}</Typography>
                            </Grid>

                            <Grid item flexDirection={"row"} flexGrow={1} sm={10} md={4} lg={4} alignItems={"center"}
                                  alignContent={"center"}>
                              <Box borderRadius={8} border={1} borderColor={palette.background.border} display={"block"}
                                   position={"relative"}>
                                <Typography variant={"subtitle1"}
                                            sx={{
                                              backgroundColor: palette.background.paper,
                                              position: "absolute",
                                              top: -16,
                                              left: 48,
                                              paddingLeft: 1,
                                              paddingRight: 1,
                                              paddingTop: 0,
                                              paddingBottom: 0
                                            }}>
                                  Roles
                                </Typography>
                                <Grid item gap={2} padding={2} justifyContent={"flex-start"}
                                      alignContent={"flex-start"}>
                                  <FormControlLabel
                                          control={<Checkbox checked={p.role.split('|').some(x => x === RoleStrings.GroupAdmin)} onChange={(e) => onRoleChange(e, p)}
                                                             value={RoleStrings.GroupAdmin}></Checkbox>}
                                          label={'Captain'}></FormControlLabel>
                                  <FormControlLabel
                                          control={<Checkbox checked={p.role.split('|').some(x => x === RoleStrings.Participant)}  onChange={(e) => onRoleChange(e, p)}
                                                             value={RoleStrings.Participant}></Checkbox>}
                                          label={RoleStrings.Participant}></FormControlLabel>
                                  <FormControlLabel
                                          control={<Checkbox checked={p.role.split('|').some(x => x === RoleStrings.Coach)}  onChange={(e) => onRoleChange(e, p)}
                                                             value={RoleStrings.Coach}></Checkbox>}
                                          label={RoleStrings.Coach}></FormControlLabel>
                                  <FormControlLabel
                                          control={<Checkbox checked={p.role.split('|').some(x => x === RoleStrings.Admin)}  onChange={(e) => onRoleChange(e, p)}
                                                             value={RoleStrings.Admin}></Checkbox>}
                                          label={RoleStrings.Admin}></FormControlLabel>
                                </Grid>
                              </Box>
                            </Grid>
                            <Grid flexGrow={1} item display={'flex'} justifyContent={"flex-end"} flexDirection={"row"}>
                              <IconButton onClick={onRemoveParticipant} value={p.email}
                                          sx={{visibility: (p.isNew ? 'inherit' : 'hidden')}}>
                                <Delete/>
                              </IconButton>
                            </Grid>
                          </Grid>
                        </ListItem>
                )
              })}
            </List>

          </DialogContent>);
        }