import {ApiService}               from "./ApiService";
import apiResponse, {ApiResponse} from "./ApiResponse";

import {Lookup} from "../views/Lookup";

export class TimeframeStore {
  constructor(private readonly api: ApiService = new ApiService()) {
  }

  public async readAll(): Promise<ApiResponse<Lookup[]>> {
    try {
      const response = await this.api.get('/timeframe')
      return apiResponse.Success<Lookup[]>(response)
    } catch (e) {
      return apiResponse.HandleError(e)
    }

  }
}