import {Survey}                   from "../models/Survey";
import {ApiService}               from "./ApiService";
import apiResponse, {ApiResponse} from "./ApiResponse";
import {CohortSurvey}             from "../models/CohortSurvey";
import {IApiService}              from "./IApiService";
import {IUpdater, IWriter}        from "./Store";


export interface SurveyReader {
  ReadAll(): Promise<ApiResponse<Survey[]>>

  ReadOne(surveyId: number): Promise<ApiResponse<CohortSurvey>>

  ReadAllCohortSurveys(cohortId: number, surveyId: number): Promise<ApiResponse<CohortSurvey>>
}

export interface SurveyWriter extends IWriter<Survey> {
  Write(survey: Survey): Promise<ApiResponse>
}

export interface SurveyUpdater extends IUpdater<Survey> {
  Update(survey: Survey): Promise<ApiResponse>
}

export class SurveyStore implements SurveyReader, SurveyWriter, SurveyUpdater {
  constructor(private readonly api: IApiService = new ApiService()) {

  }

  public async Write(survey: Survey) {
    try {
      const response = await this.api.post('/survey', survey);
      return apiResponse.Success(response);
    } catch (e) {
      return apiResponse.HandleError(e);
    }
  }

  public async ReadAllCohortSurveys(cohortId: number, surveyId: number) {
    try {
      const response = await this.api.get(`/Cohort/${cohortId}/survey/${surveyId}`)
      return apiResponse.Success<CohortSurvey[]>(response);
    } catch (err) {

      return apiResponse.HandleError(err);
    }
  }

  public async ReadCohortOnboardingSurvey(groupId: number): Promise<ApiResponse<Survey>> {
    try {
      const response = await this.api.get(`/Cohort/${groupId}/survey/onboarding`);
      return apiResponse.Success(response)
    } catch (e) {
      return apiResponse.HandleError(e);
    }
  }



  public async ReadAll() {
    try {
      const response = await this.api.get(`survey`)
      return apiResponse.Success(response);
    } catch (err) {

      return apiResponse.HandleError(err);
    }

  }

  public async ReadOne(surveyId: number) {
    try {
      const response = await this.api.get(`survey/${surveyId}`)
      return apiResponse.Success(response);
    } catch (err) {

      return apiResponse.HandleError(err);
    }
  }

  public async Update(survey: Survey) {
    try {
      const response = await this.api.put('/survey', survey);
      return apiResponse.Success(response);
    } catch (e) {

      return apiResponse.HandleError(e);
    }
  }
}



