import {DateTime}   from "luxon";
import {ToDateTime} from "../utils/DateUtils";

export interface MessageView {
  id: string,
  text: string,
  author: string,
  myMessage: boolean
  date: DateTime,
  replies: MessageView[],
  avatarUrl: string,
}

const empty: MessageView = {
  id: '',
  text: '',
  author: '',
  myMessage: false,
  avatarUrl: '',
  date: ToDateTime('1970-01-01'),
  replies: [],
}

const defaultExport = {Empty: empty}
export default defaultExport;