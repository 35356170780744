import {DateTime}   from "luxon";
import {ApiService} from "./ApiService";
import apiResponse  from "./ApiResponse";
import {Group}      from "../models";

export class CohortSurveyStore {
  constructor(private readonly api: ApiService = new ApiService()) {
  }

  public async Write(surveyId: number, cohortId: number, items: {
    cohortId: number
    surveyId: number
    sectionId: number
    availableDate: DateTime
  }[]) {
    try {
      const result = await this.api.post(`/Cohort/${cohortId}/survey/${surveyId}`, items)
      return apiResponse.Success<Group>(result)
    } catch (e) {
      return apiResponse.HandleError(e);
    }
  }
}

