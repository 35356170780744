import {Cookies}            from 'react-cookie';
import {IApiService}        from './IApiService';
import {BasicFetchResponse} from './ApiResponse';
import logger               from "../utils/logger";

export class ApiService implements IApiService {
  private baseUrl: string;
  private cookies: Cookies;

  constructor(
    baseUrl: string = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000/api',
    cookies: Cookies = new Cookies()
  ) {
    this.baseUrl = baseUrl;
    this.cookies = cookies;
  }

  public async post(url: string, data: any): Promise<BasicFetchResponse> {
    return this.request(this.normalizeUrl(url), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  }

  public async get(url: string): Promise<BasicFetchResponse> {
    return this.request(this.normalizeUrl(url), {
      method: 'GET',
    });
  }

  public async delete(url: string): Promise<BasicFetchResponse> {
    return this.request(this.normalizeUrl(url), {
      method: 'DELETE',
    });
  }

  public async put(url: string, data: any): Promise<BasicFetchResponse> {
    return this.request(this.normalizeUrl(url), {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  }

  private normalizeUrl(url: string) {
    if (url[0] !== '/')
      url = `/${url}`
    return url;
  }

  private async request(url: string, options: RequestInit): Promise<BasicFetchResponse> {
    const token = this.cookies.get('auth');
    const profile = this.cookies.get('profile');
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    if (profile) {
      options.headers = {
        ...options.headers,
        'x-user-id': profile.id,
      }
    }
    const response = await fetch(this.baseUrl + url, options);
    const contentType = response.headers.get('Content-Type');
    logger.debug(`Content-Type: ${contentType}`);
    let data;
    if (contentType && contentType.includes('application/json')) {
      data = await response.json();
    } else {
      data = await response.text();
    }
    return {
      status: response.status,
      statusText: response.statusText,
      data,
    } as BasicFetchResponse;
  }
}
