import {ApiService} from "./ApiService";
import apiResponse  from "./ApiResponse";

export class TwilioUserService {
  constructor(private readonly api: ApiService = new ApiService()) {
  }

  public async Write(email: string) {
    try {
      const response = await this.api.post('/user/twilio', {identity: email})
      return apiResponse.Success(response);
    } catch (e) {
      return apiResponse.HandleError(e);
    }
  }

}