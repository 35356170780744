import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid2 as Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Snackbar,
  ToggleButton,
  Typography
} from "@mui/material";
import React, {Fragment, useCallback, useEffect, useState} from "react";
import {RecordActivityView} from "../../views/RecordActivityView";
import {AddActivityAction} from "../../actions/AddActivityAction";
import {DateTime} from "luxon";
import {ExerciseHistory} from "../../components/modals/ExerciseHistory";
import {ActivityHistoryView} from "../../views/ActivityHistoryView";
import {GoalSummaryView, valueToActivityString, valueToString} from "../../views/DashboardView";
import {RecordExercise} from "../../components/modals/RecordExercise";
import {AddCircleOutline, AssignmentTurnedIn, CancelOutlined, Circle, CircleOutlined} from "@mui/icons-material";
import {DebugPanel} from "../../components/DebugPanel";
import {useUiState} from "../../utils/UiStateHook";
import {palette} from "../../palette";
import logger from "../../utils/logger";
import {CreateGoalForm} from "../../components/CreateGoalForm";
import {RawGoal} from "../../views/RawGoal";
import {GoalView} from "../../views/GoalView";

interface GoalTrackerCardProps {
  cohortUserId: number,
  goals: { goals: GoalSummaryView[], progress: GoalCompletionStatus },
  isLoading: boolean
  activityHistoryView: ActivityHistoryView,
  enabled?: boolean,
  newGoalEnabled?: boolean,
  disabledMessage: string,
  refreshActivities: () => void,
  jailCards: { id: number, cohortUserId: number, used: boolean, usedOn: Date }[],
  onUpdateGoal: (e: any) => void,
  week1: boolean,
  onModalClose: (e: any, reason: 'SAVE' | 'CANCEL', goals: RawGoal[]) => void;
  showNewGoalDialog: boolean
}

type GoalCompletionStatus = {
  [goalId: number]: number[]; // or [goalId: number]: number[];
};
export const GoalTrackerCard: React.FC<GoalTrackerCardProps> = ({
                                                                  enabled = false,
                                                                  newGoalEnabled = false,
                                                                  week1 = false,
                                                                  disabledMessage,
                                                                  jailCards,
                                                                  refreshActivities,
                                                                  isLoading,
                                                                  activityHistoryView,
                                                                  goals,
                                                                  cohortUserId,
                                                                  onUpdateGoal,
                                                                  showNewGoalDialog,
                                                                  onModalClose
                                                                }) => {
  const initialView: RecordActivityView = {
    duration: 0,
    name: '',
    activityId: -1,
    cohortUserId: cohortUserId,
    timeframeId: -1,
    dateStamp: DateTime.now()
  };

  const defaultView = {
    name: "",
    activity: -1,
    duration: 0,
    durationTimeframe: -1,
    frequency: -1,
    timeframe: -1,
    startDate: DateTime.local(),
    endDate: DateTime.local().plus({'weeks': 4}),
    datesEnabled: true,
    alternate: ''
  };
  const [view, setView] = useState<GoalView>(defaultView);
  const [rawGoals, setRawGoals] = useState<RawGoal[]>([]);
  const [newGoals, setNewGoals] = useState<string[]>([]);

  function addRawGoal() {
    newGoals.push(view.name)
    rawGoals.push({
      name: view.name,
      cohortUserId: cohortUserId,
      activityId: view.activity,
      duration: view.duration,
      durationTimeframeId: view.durationTimeframe,
      frequency: view.frequency,
      timeframeId: view.timeframe,
      endDate: view.endDate,
      startDate: view.startDate
    })
    setRawGoals([...rawGoals])
    setNewGoals([...newGoals])
  }

  const onAddGoal = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    addRawGoal();
    setView({...defaultView})
  }, [
    cohortUserId,
    goals,
    view,
    defaultView
  ]);
  const onRemoveGoal = useCallback((event: React.MouseEvent<HTMLDivElement>, index: number) => {
    rawGoals.splice(index, 1)
    newGoals.splice(index, 1)
    setNewGoals([...newGoals])
    setRawGoals([...rawGoals])
  }, [newGoals]);


  useEffect(() => {
    view.name = `I commit to ${valueToActivityString(view.activity)} ${view.frequency} ${valueToString(view.timeframe)} for ${view.duration} ${valueToString(view.durationTimeframe)}`
    setView({...view})
  }, [
    view.activity,
    view.duration,
    view.durationTimeframe,
    view.frequency,
    view.timeframe,
  ]);

  const [jailCardOpen, setJailCardOpen] = useState<boolean>(false);
  const [historyDialogOpen, setHistoryDialogOpen] = useState<boolean>(false);
  const [activityView, setActivityView] = useState<RecordActivityView>(initialView)
  const handleChange = (e: any) => {
    const {name, value} = e.target;
    setView({
      ...view,
      [name]: value
    })
  }
  const handleActivityChange = (e: { target: { name: string, value: any } }) => {
    const {name, value} = e.target;
    setActivityView({
      ...activityView,
      cohortUserId: cohortUserId,
      [name]: value
    })
  };
  const [activityTrackingDialogOpen, setActivityTrackingDialogOpen] = useState<boolean>(false);
  const [goalCompletionStatus, setGoalCompletionStatus] = useState<GoalCompletionStatus>({});

  useEffect(() => {
    setGoalCompletionStatus(goals.progress)
  }, [goals.progress]);

  const addActivity = async (e: any) => {
    await new AddActivityAction().Execute(activityView)
    setActivityTrackingDialogOpen(false);
  };
  const [selectedGoal, setSelectedGoal] = useState<GoalSummaryView>();
  const handleToggle = (e: any, goal: GoalSummaryView, index: number) => {
    setConfirmGoalDialogOpen(true)
    setSelectedGoal(goal)
    const goalId = goal.id

    setGoalCompletionStatus((prev) => {
      const currentStatus = prev[goalId] || Array(goal.frequency)
              .fill(0); // default to an array of 0s

      const newStatus =
              currentStatus.map((status, i) => {
                return i === index ? (status === 1 ? 0 : 1) : status // toggle between 1 and 0
              });

      return {
        ...prev,
        [goalId]: newStatus,
      };
    });
  };
  const [alertOpen, setAlertOpen] = useState(false);
  const trackActivity = async () => {
    const item = {
      activityId: selectedGoal?.activity,
      cohortUserId: cohortUserId,
      dateStamp: DateTime.now(),
      duration: selectedGoal?.duration,
      timeframeId: selectedGoal?.timeframe,
      name: selectedGoal?.goalText
    } as RecordActivityView
    await new AddActivityAction().Execute(item)
    setConfirmGoalDialogOpen(false)
    refreshActivities()
  }
  const uiState = useUiState(enabled);
  const [confirmGoalDialogOpen, setConfirmGoalDialogOpen] = useState<boolean>(false);
  const snackAlertAction = (
          <Alert variant={"filled"}
                 sx={{width: 300, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px'}}
                 onClose={(e) => {
                   setAlertOpen(false)
                 }}>
            <AlertTitle>Success!</AlertTitle>
            <Typography variant={"body1"}>WAY TO GO!</Typography>
          </Alert>)
  return <Fragment>
    <Card variant={"elevation"} sx={{
      height: 300,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'scroll',
      border: '1px solid',
      borderColor: uiState.borderColor,
      borderRadius: 4
    }}>
      <CardContent>
        <Typography variant={"h5"} component={"div"} color={uiState.color}>My Exercise Goal</Typography>
        {!enabled &&
                <Typography color={"primary"}
                            sx={{marginTop: '10%', textShadow: `1px 1px 2px ${palette.background.paper}`, textAlign: 'center', backdropFilter: uiState.backdropFilter}}
                            variant="h5" component="p" gutterBottom>{disabledMessage}</Typography>}

        {isLoading &&
                <ul>
                  <li>
                    <Typography color={uiState.color} variant={"h6"} sx={{fontWeight: 300}}>
                      <Skeleton width={'80%'}></Skeleton>
                    </Typography>
                  </li>
                </ul>
        }
        {!isLoading &&
                <Grid container display={"flex"} flexDirection={"column"}>
                  {week1 &&
                          <Typography color={"primary"}
                                      sx={{marginTop: '10%', textShadow: `1px 1px 2px ${palette.background.paper}`, textAlign: 'center', backdropFilter: uiState.backdropFilter}}
                                      variant="h5" component="p" gutterBottom>Don't worry! We won't start tracking goals
                            until week 1.</Typography>}
                  {goals.goals.map(x => (
                          <Grid key={x.id}>
                            <Typography color={uiState.color}>{x.goalText}</Typography>

                            {[...Array(x.frequency)].map((_, i) => (
                                    <ToggleButton disabled={!enabled}
                                                  color={"primary"}
                                                  onClick={(e) => handleToggle(e, x, i)}
                                                  selected={goalCompletionStatus[x.id]?.includes(i)}
                                                  key={i}
                                                  sx={{border: 0, borderRadius: 30, padding: .25, margin: .5}}
                                                  value={`${x.id}-${i}`}
                                    >
                                      {goalCompletionStatus[x.id]?.[i] === 1 ? <Circle color={uiState.buttonColor}/> :
                                              <CircleOutlined color={uiState.buttonColor}/>}
                                    </ToggleButton>
                            ))}

                          </Grid>
                  ))}
                </Grid>
        }
      </CardContent>
      {enabled && <CardActions sx={{alignItems: 'flex-end', justifyContent: "flex-end", flexGrow: 1}}>
        <Button onClick={() => setJailCardOpen(true)}>Show Available Jail Cards</Button>
        <Button disabled={!enabled} variant={"contained"} onClick={() => {
          refreshActivities()
          setHistoryDialogOpen(true)
        }}>View Recent Exercise</Button>
        {newGoalEnabled &&
                <Button disabled={!newGoalEnabled} variant={"contained"} onClick={onUpdateGoal}>Update Goals</Button>}


      </CardActions>}
    </Card>
    <RecordExercise onChange={handleActivityChange}
                    view={activityView}
                    open={activityTrackingDialogOpen}
                    onClose={() => {
                      setActivityTrackingDialogOpen(false)
                    }}
                    onSave={addActivity}></RecordExercise>
    <ExerciseHistory open={historyDialogOpen} onClose={() => setHistoryDialogOpen(false)}
                     view={activityHistoryView}></ExerciseHistory>

    <Dialog open={jailCardOpen} onClose={() => setJailCardOpen(false)}>
      <DebugPanel displayItem={jailCards}></DebugPanel>
      <DialogTitle>Available Get Out Of Jail Cards</DialogTitle>
      <DialogContent>
        <Typography component={"div"}>{"Available Get Out of Jail Free Cards:"}<Typography sx={{paddingLeft: 2}}
                                                                                           component={"span"}
                                                                                           variant={"h4"}>
          {jailCards.length}
        </Typography>
        </Typography>
        <List>
          {jailCards.map(x => {
            return (
                    <ListItem key={x.id}>
                      <img src={"https://images.ctfassets.net/ihnuhnmumsts/SvOPfL2qrjRB68cpu5e96/3ff57b3375721b425c6ce8eade8dfcbf/B34EE134-411A-4A51-98B2-D45705EAEA72.png?fm=webp&q=75"}
                           alt={"Jail"}></img>
                    </ListItem>
            )
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setJailCardOpen(false)}>Dismiss</Button>
      </DialogActions>
    </Dialog>

    <Dialog open={confirmGoalDialogOpen}>
      <DebugPanel displayItem={selectedGoal}></DebugPanel>
      <DialogTitle>
        Confirm Goal
      </DialogTitle>
      <DialogContent>
        <Typography>Did you complete a workout towards this part of your goal?</Typography>
        <Typography>{selectedGoal?.goalText}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          setConfirmGoalDialogOpen(false)
          setGoalCompletionStatus(goals.progress)
        }}>Cancel</Button>
        <Button variant={'contained'} onClick={(e: any) => {
          trackActivity()
                  .then(() => {
                    setAlertOpen(true)
                  })
                  .catch(err => {
                    logger.error(err)
                  })

        }}>Confirm</Button>
      </DialogActions>
    </Dialog>


    <Snackbar anchorOrigin={{vertical: "top", horizontal: "center"}} autoHideDuration={6000} open={alertOpen}
              onClose={() => setAlertOpen(false)}>
      {snackAlertAction}
    </Snackbar>

    <Dialog open={showNewGoalDialog}
            onClose={(e: any) => onModalClose(e, 'CANCEL', [])}

            slotProps={{backdrop: {style: {backgroundColor: 'background.paper'}}}}>
      <DebugPanel displayItem={view}></DebugPanel>
      <DialogTitle>Add new Goal</DialogTitle>
      <Divider></Divider>
      <DialogContent sx={{display: 'flex', flexDirection: 'column', gap: 4, overflow: 'scroll'}}>
        <CreateGoalForm formState={view} handleChange={handleChange}/>
        <IconButton sx={{alignSelf: "flex-start"}} color={"primary"} size={"small"}
                    onClick={onAddGoal}><AddCircleOutline/> Add Goal</IconButton>
        {newGoals.length > 0 && <Box sx={{border: 1, borderRadius: 5, borderColor: palette.background.border}}>
          <List>
            {newGoals.map((goal, idx) => {
              return (<ListItem>
                <ListItemIcon><AssignmentTurnedIn color={"primary"}/></ListItemIcon>
                <ListItemText>{goal}</ListItemText>
                <ListItemButton onClick={(e) => onRemoveGoal(e, idx)}><CancelOutlined
                        color={"primary"}/></ListItemButton>
              </ListItem>)
            })}
          </List>
        </Box>}
      </DialogContent>
      <DialogActions>
        <Button disableElevation
                color={"secondary"}
                size={"large"}
                variant={"contained"}
                onClick={(e: any) => onModalClose(e, 'CANCEL', [])}>Cancel</Button>
        <Button variant={"contained"}
                size={"large"}
                onClick={(e: any) => {
                  addRawGoal();
                  onModalClose(e, 'SAVE', rawGoals)
                }
                }>Save</Button>
      </DialogActions>
    </Dialog>

  </Fragment>
}

