import React, {FC, Fragment, useEffect, useState} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import {styled} from '@mui/material/styles';
import {DashboardView} from "../../views/DashboardView";
import {
  Badge,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2 as Grid,
  Skeleton
} from "@mui/material";
import {ParticipantView} from "../../views/ParticipantView";
import {useTwilioClient} from "../../TwilioClientContext";
import {useCookies} from "react-cookie";
import {HashUtils} from "../../utils/HashUtil";
import {ChatConversation} from "../ChatConversation";
import messageViewModel, {MessageView} from "../../views/MessageView";
import {Conversation} from "@twilio/conversations";
import {MessagesViewCreator} from "../../view-creator/MessagesViewCreator";
import logger from "../../utils/logger";
import {DebugPanel} from "../DebugPanel";
import {palette} from "../../palette";
import {useLocation, useNavigate} from "react-router-dom";

const StyledCard = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  minHeight: 300,
  border: '1px solid',
  borderColor: palette.primary.main,
  borderRadius: 16
});

interface TeamCardProps {
  view: DashboardView,
  isLoading: boolean
}

type TeamConversations = { [uniqueName: string]: Conversation }
export const TeamCard: FC<TeamCardProps> = ({isLoading, view}: TeamCardProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {client, loading} = useTwilioClient()
  const [cookies] = useCookies(['profile'])
  const [conversationModalOpen, setConversationModalOpen] = useState<boolean>(false);
  const [conversationView, setConversationView] = useState<MessageView[]>([messageViewModel.Empty]);
  const [conversation, setConversation] = useState<Conversation | undefined | null>();
  const [teamConversations, setTeamConversations] = useState<TeamConversations>({});
  const [newMsg, setNewMsg] = useState<string>("");
  const [isClientLoading, setIsClientLoading] = useState<boolean>(loading);
  const [selectedParticipant, setSelectedParticipant] = useState<ParticipantView>();
  const [loadMessages, setLoadMessages] = useState<boolean>(false);
  const [unreadMessages, setUnreadMessages] = useState<{ [participantId: number]: number | null }>({});
  const [initialized, setInitialized] = useState<boolean>(false);

  const initializeConversations = async () => {
    if (!loading && client) {
      logger.log('iterating over participants...')
      for (const participant of view.team.participants) {
        logger.log('..... participant: ', JSON.stringify(participant));
        const uniqueName = await createFriendlyName(participant)
        logger.log('..... getting unique name...', uniqueName)
        if (participant.email === cookies.profile.email) {
          logger.log('..... skipping participant, because its me')
          continue;
        }
        let dm: Conversation | null = null;
        try {
          logger.log('..... getting DM conversation by unique name...')
          dm = await client.getConversationByUniqueName(uniqueName)
          if (dm && dm.status === 'notParticipating') {
            await dm.add(cookies.profile.email)
          }
        } catch (e) {
          logger.log('..... got an error getting conversation...', e)
        }
        if (!dm) {
          logger.log('..... trying to create a conversation since getting by unique name failed...')
          dm = await client.createConversation({friendlyName: uniqueName, uniqueName: uniqueName})
          logger.log('..... created conversation...', dm.sid)
          logger.log('.....' + `adding me and ${participant.email} to the conversation...`)
          await Promise.all([
            dm?.add(participant.email),
            dm?.add(cookies.profile.email)
          ])
        }

        dm.on('messageAdded', () => {
          logger.log('..... When a message is added to the conversation, get the unread message count.')

          loadUnReadMessageCount(dm, participant)
                  .catch(logger.error)
        })
        logger.log('..... saving conversation in collection...')
        teamConversations[uniqueName] = dm
        logger.log('..... saving conversation collection...')
        setTeamConversations({...teamConversations})
      }
      logger.log('initialized complete')
      setInitialized(true);
    }
  }

  useEffect(() => {
    if (view?.team?.participants) {
      logger.log('view is ready.')
      logger.log('initializing Conversations...')
      initializeConversations()
              .catch(logger.error)
      logger.log('loading unread conversations...')
      loadAllUnreadMessageCount()
              .catch(logger.error)
    }
  }, [
    view.team.participants,
    loading
  ]);

  useEffect(() => {
    logger.debug('loading state changed:', loading);
    setIsClientLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (isClientLoading) {
      logger.debug('still loading the client...setting reload to false');
      setLoadMessages(false)
      return;
    }
    if (loadMessages && initialized) {
      getMessages();
    }
  }, [
    isClientLoading,
    loadMessages
  ]);

  useEffect(() => {
    const hash: string = location.hash;
    if (hash.startsWith("#message/")) {
      const id = hash.split("/")[1]; // Extract participantId from the hash
      const item = view.team.participants.find(p => p.id === Number(id))
      if (item) {
        loadConversation(item)
        setConversationModalOpen(true);
      }
    }
  }, [
    location.hash,
    view
  ]);

  const loadAllUnreadMessageCount = async () => {
    if (client) {
      for (const participant of view.team.participants) {
        if (participant.email === cookies.profile.email) {
          continue;
        }
        const uniqueName = await createFriendlyName(participant)
        const dm = teamConversations[uniqueName]
        await loadUnReadMessageCount(dm, participant)
      }
    }
  }

  const loadUnReadMessageCount = async (dm: Conversation | undefined, participant: ParticipantView) => {
    if (dm) {
      let unreadMessageCount: number | null | void = null;
      unreadMessageCount = await dm.getUnreadMessagesCount()
                                   .catch(logger.error)

      if (unreadMessageCount) {
        setUnreadMessages(() => {
          unreadMessages[participant.id] = unreadMessageCount
          return {...unreadMessages}
        })
      }
    }
  }

  const getMessages = async () => {
    try {
      if (conversation) {
        logger.debug('Conversation loaded:', conversation);

        if (loadMessages) {
          const messages = await conversation.getMessages(15)
          const view = new MessagesViewCreator().createViewWithArg({
            items: messages.items, email: cookies.profile.email
          })
          setConversationView(view)
        }
      }
    } catch (e) {
      logger.error('Error loadMessages -->', e)
    } finally {
      setLoadMessages(false);
    }
  }

  async function createFriendlyName(participant: ParticipantView) {
    const chatParticipants = [
      view.cohortUserId,
      participant.CohortUser.id
    ].sort((a, b) => a - b)
     .join('+')
    const friendlyName = await HashUtils.sha256Hash(chatParticipants)
    return friendlyName;
  }

  const loadConversation = async (participant: ParticipantView) => {
    setSelectedParticipant(participant)
    setConversationView([])
    try {
      if (!client) {
        logger.debug('Client is not ready yet');
        return;
      }
      const friendlyName = await createFriendlyName(participant);
      const convo = teamConversations[friendlyName];
      try {
        if (convo && convo.status === 'notParticipating') {
          await convo.add(cookies.profile.email)
        }
      } catch (e) {
        // swallow error, likely conversation needs to be created
      }
      teamConversations[friendlyName] = convo
      setTeamConversations({...teamConversations})
      convo.on('messageAdded', (message) => {
        setLoadMessages(true)
      })
      setConversation(convo)
      setLoadMessages(true)
      await convo.setAllMessagesRead()
      setUnreadMessages(() => {
        if (selectedParticipant)
          unreadMessages[selectedParticipant.id] = 0
        return {...unreadMessages}
      })

    } catch (error) {
      logger.error("Error loading conversation or messages:", error);
    } finally {
      logger.debug('Setting reload to false after loading conversation');
    }
  }
  const handleSend: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    try {
      logger.debug('sending message changed...', newMsg);
      logger.debug('convo -->', conversation);

      if (!conversation) {
        logger.warn('Convo is null, cannot send message');
        return;
      }
      if (newMsg && newMsg.length > 0) {
        const body = newMsg;
        const idx = await conversation?.sendMessage(body);
        await conversation.updateLastReadMessageIndex(idx)
        const unread = await conversation.getUnreadMessagesCount();
        setUnreadMessages(x => {
          if (selectedParticipant) {
            x[selectedParticipant.id] = unread;
          }
          return {...x}
        })
        setNewMsg('');
      }
    } finally {
      setLoadMessages(true)
    }
  };
  const handleMessageChange = (e: any) => {
    setNewMsg(e.currentTarget.value);
  };
  const handleModalClose = () => {
    setConversation(null)
    setConversationModalOpen(false);
    navigate('', {relative: "path"})
  }


  return (<Fragment>
    <StyledCard>
      <CardContent>
        <Typography variant="h5" component={"div"} color={palette.primary.main}>Direct Messaging</Typography>
        <Grid display={"flex"} justifyContent={"space-around"} container>
          {isLoading && !initialized && <CircularProgress variant={"indeterminate"}></CircularProgress>}
          {isLoading && !initialized &&
                  <Grid display={"flex"} maxWidth={'100%'} flexBasis={'100%'} size={{xs: 6, sm: 12, md: 12, lg: 6}}>
                    <Button sx={{display: 'flex', flexDirection: 'row', gap: 2}}>
                      <Skeleton variant="circular" width={40} height={40}/>
                      <Typography component={"span"}>
                        <Skeleton variant="text" width={150}/>
                        <Skeleton variant="text" width={150}/>
                      </Typography>
                    </Button>
                  </Grid>
          }
          {!isLoading && initialized && view.team.participants.map(participant => {
            return (
                    <Grid display={"flex"} maxWidth={'100%'} flexBasis={'100%'} key={participant.id}
                          size={{xs: 6, sm: 12, md: 12, lg: 6}}>

                      {!participant.name &&
                              <Button>
                                <Skeleton variant="circular" width={40} height={40}/>
                                <Typography component={"span"}>
                                  <Skeleton variant="text" width={150}/>
                                  <Skeleton variant="text" width={150}/>
                                </Typography>
                              </Button>
                      }
                      {participant.name &&
                              <Button onClick={(e) => navigate(`#message/${participant.id}`)}>
                                <Badge badgeContent={unreadMessages[participant.id]} color="primary">
                                  <Avatar sx={{marginRight: 1}} alt={participant.name} src={participant.avatarUrl}/>
                                </Badge>
                                <Typography component={"span"}>{participant.name}</Typography>
                              </Button>
                      }

                    </Grid>)
          })}
        </Grid>
      </CardContent>
    </StyledCard>
    <Dialog onClose={handleModalClose} fullWidth open={conversationModalOpen}>
      <DebugPanel
              displayItem={{state: conversation?.state, status: conversation?.status, uniqueName: conversation?.uniqueName}}/>
      <DialogTitle>{`Direct Message with ${selectedParticipant?.name || selectedParticipant?.email}`}</DialogTitle>
      <DialogContent>
        <ChatConversation autoScroll unreadMessages={0}
                          newMsg={newMsg}
                          view={conversationView}
                          onSend={handleSend}
                          onMessageChange={handleMessageChange}/>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleModalClose}>Close</Button>
      </DialogActions>
    </Dialog>
  </Fragment>);
};
