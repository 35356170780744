import {ApiService} from "./ApiService";
import apiResponse  from "./ApiResponse";

export class UserStore {
  constructor(private readonly api: ApiService = new ApiService()) {
  }

  public async MergeUsers(cohortId: number, user: { id: number; email: string; role: string }) {
    try {
      const response = await this.api.post('/cohortuser/associate', {cohortId: cohortId, raw: user})
      return apiResponse.Success(response);
    } catch (e) {

      return apiResponse.HandleError(e);
    }
  }
}
