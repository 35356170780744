import actionResponse, {ActionResponse} from "./ActionResponse";
import {RawGoal}                        from "../views/RawGoal";
import apiResponse                      from "../api/ApiResponse";
import {GoalStore}                      from "../api/GoalStore";

export default class CreateGoalAction {
  constructor(private readonly store: GoalStore = new GoalStore()) {
  }

  public Execute = async (goals: RawGoal[]): Promise<ActionResponse> => {
    try {
      await apiResponse.handleApiResponse(() => this.store.Write(goals))
      return actionResponse.Success('saved goals')
    } catch (e) {
      return actionResponse.HandleError(e)
    }
  }
}