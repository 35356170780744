export type ActionResponse = {
  status: 'SUCCESS' | 'VALIDATION_ERROR' | 'ERROR'
  statusMessage: string
  error: Error | null | unknown
  result: any | null
}

const successResponse = (message: string, result?: any): ActionResponse => {
  return {
    status: 'SUCCESS',
    statusMessage: message,
    error: null,
    result: result
  }
}
const validationErrorResponse = (validationMessage: string): ActionResponse => {
  return {
    status: 'VALIDATION_ERROR',
    error: new Error(validationMessage),
    statusMessage: validationMessage,
    result: null
  }
}

/**
 * @param errMsg
 */
const errorResponse = (errMsg: any): ActionResponse => {
  return {
    status: 'ERROR',
    error: new Error(errMsg),
    statusMessage: errMsg,
    result: null
  }
}

export const handleError = (err: unknown): ActionResponse => {
  let message, error
  if (err instanceof Error) {
    error = err as Error
    console.error(error)
    return errorResponse(error.message)

  } else message = String(err)
  console.error(message)
  return errorResponse(message)
};

const defaultExport = {
  Success: successResponse,
  ValidationError: validationErrorResponse,
  Error: errorResponse,
  HandleError: handleError
}

export default defaultExport