/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField, Typography
} from "@mui/material";
import React, {ChangeEvent, ChangeEventHandler, Fragment, PropsWithChildren, useEffect, useState} from "react";
import {LoginButton} from "../components/LoginButton";
import {useQuery} from "../hooks";
import {AcceptInviteViewCreator} from "../view-creator/AcceptInviteViewCreator";
import View, {AcceptInviteView} from "../views/AcceptInviteView";
import {ContentStore} from "../api/ContentStore";
import {marked} from "marked";


export const AcceptInvite: React.FC<PropsWithChildren> = () => {
  const viewCreator = new AcceptInviteViewCreator()
  const query = useQuery();
  const [view, setView] = useState<AcceptInviteView>({...View.Empty});
  const [terms, setTerms] = useState<{title: string, content: string}>({title: '', content: ''});
  useEffect(() => {
   new ContentStore().readByContentType('termsAndConditions',  (item) => {
      return {
        title: String(item.fields['title']),
        content: marked(String(item.fields['content']), {async: false}),
      }
    }).then(content => setTerms(content[0]))
  }, []);


  useEffect(() => {
    setView(viewCreator.createViewWithArg({inviteCode: query.get('code'), acceptTerms: false}))
  }, []);

  const loginClickHandler = () => {

  };
  const checkHandler: ChangeEventHandler<HTMLInputElement> = (e: ChangeEvent<HTMLInputElement>) => {
    const termsAccepted = e.currentTarget.checked
    setView(viewCreator.createViewWithArg({inviteCode: query.get('code'), acceptTerms: termsAccepted}))
  };
  const [showTerms, setShowTerms] = useState(false);
  return (
          <Fragment>
          <Container maxWidth={"md"}>
            <LoginButton loginButtonHelp={view.loginButtonHelp} onLoginClicked={loginClickHandler}
                         disabled={!view.loginEnabled}
                         authenticationRedirectUrl={view?.authRedirectUrl}>
              <FormControlLabel control={<Checkbox size={"large"} onChange={checkHandler} checked={view.termsAccepted}></Checkbox>}
                                label={<Typography>
                                  By selecting this checkbox you agree to our <Button sx={{margin: 0, padding: 0}} variant={"text"} onClick={() => setShowTerms(true)}> terms and conditions</Button>
                                </Typography> }>
              </FormControlLabel>
            </LoginButton>
          </Container>
            <Dialog open={showTerms}>
              <DialogTitle>Terms and Conditions</DialogTitle>
              <DialogContent>
                <Typography variant={"caption"} dangerouslySetInnerHTML={{__html: terms.content}}></Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setShowTerms(false)}>Dismiss</Button>
              </DialogActions>
            </Dialog>
          </Fragment>
  );
};