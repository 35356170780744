import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface TokenState {
  token: string
}

const initialState: TokenState = {
  token: ''
}

const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    receivedToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
    }
  }
});

export const {receivedToken} = tokenSlice.actions
export default tokenSlice.reducer;