import {Card, CardActions, CardContent, Skeleton, SxProps, Typography} from "@mui/material";
import React, {CSSProperties, forwardRef, Fragment, PropsWithRef} from "react";
import {palette} from "../../palette";
import {useUiState} from "../../utils/UiStateHook";

interface PotCardProps extends PropsWithRef<any> {
  label: string,
  value: string,
  cohortUserId: number,
  isLoading: boolean,
  enabled?: boolean,
  disabledMessage: string,
}

export const PotCard: React.FC<PotCardProps> = forwardRef(({enabled = false, disabledMessage, isLoading, label, value}, ref: React.ForwardedRef<any>) => {
  const uiState = useUiState(enabled);


  // Function to map string to value
  const backgroundStyles: CSSProperties = {
    backgroundImage: `url(${enabled ? '//images.ctfassets.net/ihnuhnmumsts/3yHNDAFLHsMSMjx7h7RDor/8b676d74b569ac0e68787720b6752e37/Untitled_design__11_.png?h=250' : '//images.ctfassets.net/ihnuhnmumsts/15UTZUC0qcn3n13jD599D3/7bc611cc1b436dbf976222f0cb4e2f1a/Untitled_design__11_disabled.png?h=250'})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: "top right",
    border: '1px solid',
    borderColor: uiState.backgroundColor,
    borderRadius: 16
  };

  const contentStyles: SxProps = {}


  return (
          <Fragment>
            {isLoading && (
                    <Card style={backgroundStyles} variant={"elevation"} sx={{height: 300}}>
                      <CardContent sx={contentStyles}>
                        <Typography variant={"h4"} component={"p"}>
                          <Skeleton width="80%"/>
                        </Typography>
                        <Typography variant={"h3"} component={"p"}>
                          <Skeleton width="60%"/>
                        </Typography>
                      </CardContent>
                      <CardActions sx={{justifyContent: "end", height: "90%"}}>
                        <Skeleton variant="rectangular" width={100} height={40}/>
                      </CardActions>
                    </Card>
            )}
            {!isLoading &&
                    <Card style={backgroundStyles} variant={"elevation"} sx={{height: 300}}>
                      <CardContent sx={contentStyles}>
                        <Typography variant={"h5"} component={"p"} color={uiState.color}>{label}</Typography>
                        {!enabled &&
                                <Typography
                                        sx={
                                          {
                                            marginTop: '10%',
                                            color: palette.primary.main,
                                            textShadow: `1px 1px 2px ${palette.background.paper}`,
                                            textAlign: 'center',
                                            backdropFilter: uiState.backdropFilter
                                          }}
                                        variant="h5" component="p"
                                        gutterBottom>{disabledMessage}</Typography>}
                        {enabled && <Typography variant={"h3"} color={uiState.color} component={"p"} sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          margin: '44px'
                        }}>{value}</Typography>}
                      </CardContent>
                      <CardActions
                              sx={
                                {
                                  justifyContent: 'flex-end',
                                  flexGrow: 1,
                                  flexDirection: 'column',
                                  alignItems: 'start'
                                }}>

                      </CardActions>
                    </Card>}


          </Fragment>
  )
})