import {useCallback, useEffect, useMemo, useState} from 'react';
import {useAppDispatch} from './hooks';
import {useCookies} from 'react-cookie';
import {receivedToken} from './reducers/TokenReducer';
import {receivedGoogleAuthToken} from './reducers/Auth.slice';
import {ApiResponse} from './api/ApiResponse';
import {ChatStore} from "./api/ChatStore";

export const useApp = () => {
  const [cookies] = useCookies([
    'auth',
    'profile'
  ]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [reload, setReload] = useState<boolean>(true);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const chatStore = useMemo(() => new ChatStore(), []);
  const dispatch = useAppDispatch();

  const initializeToken = useCallback(async () => {
    dispatch(receivedGoogleAuthToken(cookies.auth));

    if (cookies.profile) {
      const response: ApiResponse<string> = await chatStore.CreateChatToken(cookies.profile.email);
      if (response.status === 'ERROR') {
        console.error(response.statusMessage);
        throw Error(response.statusMessage);
      }
      const chatToken = response.result ?? '';
      dispatch(receivedToken(chatToken));
      setReload(false);
    }
  }, [
    cookies.profile,
    cookies.auth,
    chatStore,
    dispatch
  ]);

  useEffect(() => {
    if (reload) {
      initializeToken();
    }
  }, [
    reload,
    initializeToken
  ]);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget as HTMLElement);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    setAnchorEl(null);
    window.location.href = (process.env.REACT_APP_BASE_SERVER_URL || 'http://localhost:3000') + '/auth/logout';
  };

  const toggleDrawer = (open: boolean) => {
    setMenuOpen(open);
  };

  return {
    cookies,
    anchorEl,
    menuOpen,
    handleClick,
    handleClose,
    logout,
    toggleDrawer,
  };
};
