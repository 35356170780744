import React, {useEffect, useState} from "react";
import {Container} from "@mui/material";
import {Navigate, useNavigate} from "react-router-dom";
import {useAppSelector} from "../hooks";
import {LoginButton} from "../components/LoginButton";

interface HomeProps {
  children?: any
}

export const Home: React.FC<HomeProps> = ({children}) => {
  const auth = useAppSelector((state) => state.auth)
  const [authenticated, setAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    if (auth.token) {
      setAuthenticated(true)
    }
  }, [auth.token]);

  return (
          <>
            {authenticated && <Navigate to="/groups"></Navigate>}
            {!authenticated && <Container maxWidth={"md"}>
              <LoginButton loginButtonHelp={"Click here to login"}></LoginButton>
            </Container>}
          </>
  )

}