import {Button, ButtonProps} from "@mui/material";
import React from "react";

interface HideableButtonProps extends ButtonProps {
  visible: boolean
}

export const HideableButton: React.FC<HideableButtonProps> =
  ({visible, children, ...props}) => {
    if (visible)
      return (<Button {...props}>{children}</Button>)
    return null;
  }
;