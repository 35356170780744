import {GroupsView}       from "../views/GroupsView";
import GroupViewModel     from "../views/GroupView";
import {GroupViewCreator} from "./GroupViewCreator";
import {Group}            from "../models";
import apiResponse  from "../api/ApiResponse";
import {GroupStore} from "../api/GroupStore";


export class GroupsViewCreator {
  constructor(private readonly store: GroupStore = new GroupStore()) {
  }

  public async CreateView(): Promise<GroupsView> {
    const groups = await apiResponse.handleApiResponse<Group[]>(() => this.store.ReadAll());

    const groupViewCreator = new GroupViewCreator();

    return {
      groups: await Promise.all(groups.map(async (g: Group) => await groupViewCreator.CreateView(g))),
      selectedGroup: GroupViewModel.Empty,
      groupsUpdated: false,
      newGroup: GroupViewModel.Empty,
      editGroupModalVisible: false,
      newGroupModalVisible: false,
      participantsDialogVisible: false
    };
  }

}

