import {ContentStore}    from "../api/ContentStore";
import {DateTime}        from "luxon";
import {marked}          from "marked";
import {GroupDateMapper} from "../utils/GroupDateMapper";

export type ExerciseContent = {
  content: { title: string, body: string, offset: number, duration: number },
}

export class ExerciseInDisguiseContentViewCreator {
  constructor(private readonly content: ContentStore = new ContentStore()) {

  }

  public async CreateView(groupStart: DateTime): Promise<ExerciseContent> {
    const contentItems = await this.content.readByContentType('exercise-in-disguise', (item) => {
      return {
        title: String(item.fields['title']),
        body: marked(item.fields['body']),
        offset: Number(item.fields['offset']),
        duration: Number(item.fields['duration']),
      }
    })

    const dateMapper = new GroupDateMapper(groupStart.toJSDate())
    const date = DateTime.local()
                         .toISODate()
    const currentOffset = dateMapper.day(date)


    const info =
      contentItems.find((item) => item.offset <= currentOffset && (item.offset + item.duration) >= currentOffset)

    return {content: info} as ExerciseContent
  }
}