// src/enums/Role.ts

export enum Role {
  Admin = 1 << 0, // 0001
  GroupAdmin = 1 << 1, // 0010
  Participant = 1 << 2, // 0100
  Guest = 1 << 3,  // 1000
  Coach = 1 << 4
}

// Mapping between enum values and string representations
export const RoleStrings: { [key in keyof typeof Role]: string } = {
  Admin: 'Admin',
  GroupAdmin: 'GroupAdmin', //ToDo: Make this value Captain. Admins are employees of WHE
  Participant: 'Participant',
  Guest: 'Guest',
  Coach: 'Coach'
};

// Reverse mapping for string to enum conversion
export const RoleValues: { [key: string]: Role } = {
  'Admin': Role.Admin,
  'GroupAdmin': Role.GroupAdmin,
  'Participant': Role.Participant,
  'Guest': Role.Guest
};
