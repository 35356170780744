// src/utils/roleUtils.ts

import {Role, RoleStrings, RoleValues} from '../enums/Role';

// Convert enum to string
export const roleToString = (role: Role): string => {
  const roleKey = (Object.keys(Role) as Array<keyof typeof Role>).find(key => Role[key] === role);
  return roleKey ? RoleStrings[roleKey] : '';
};

// Convert string to enum
export const stringToRole = (roleString: string): Role | undefined => {
  return RoleValues[roleString];
};

// Function to convert array of role strings to bit values
export const roleMap = (roles: string[]): number => {
  return roles.reduce((acc, role) => acc | (stringToRole(role) ?? 0), 0);
};
