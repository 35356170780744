import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Group} from '../../models';


export interface GroupState {
  groups: Group[]
}

const initialState: GroupState = {
  groups: []
}

const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    receivedGroups(state, action: PayloadAction<Group[]>) {
      state.groups = action.payload;
    }
  }
});

export const {receivedGroups} = groupsSlice.actions
export default groupsSlice.reducer;