import {ThemeOptions} from "@mui/material";

declare module '@mui/material/styles' {
  interface Theme {
    first: {
      main: string
    },
    second: {
      main: string
    },
    third: {
      main: string
    }
  }

  interface ThemeOptions {
    first?: {
      main?: string
    },
    second?: {
      main?: string
    },
    third?: {
      main: string
    }
  }
}
const palette = {
  first: {
    main: '#2678e3',
  },
  second: {
    main: '#e3aa26',
  },
  third: {
    main: '#3ce326',
  },
  primary: {
    main: 'rgb(127,19,25)',
    main50: 'rgba(127,19,25, .5)',
    light: 'rgb(152,66,71)',
    light50: 'rgba(152,66,71, .5)',
    dark: '#580d11',
    contrastText: '#F6F2EC',
  },
  secondary: {
    main: 'rgb(246,242,236)',
    contrastText: '#1e1b1b',
    light: '#f8f0ee',
    dark: '#aca5a3',
  },
  error: {
    main: '#ff5449',
    light: '#ff766d',
    dark: '#b23a33',
    contrastText: '#f7edea',
  },
  info: {
    main: '#d4ebfa',
    contrastText: '#f7edea',
    light: '#dceffb',
    dark: '#94a4af',
  },
  success: {
    main: '#197810',
    contrastText: '#f7edea',
    light: '#47933f',
    dark: '#11540b',
  },
  warning: {
    main: '#ed6c02',
    light: '#f08934',
    dark: '#a54b01',
    contrastText: '#f7edea',
  },
  background: {
    default: 'rgb(246,242,236)',
    paper: 'rgb(246,242,236)',
    border: 'rgba(0,0,0,.23)'
  },
  divider: '#1e1b1b',
};
const themeOptions: ThemeOptions = {
  palette: palette,
  typography: {
    fontFamily: 'Poppins',
    h5: {fontFamily: 'Poppins', fontWeight: 'bold',},
    h3: {fontFamily: "\"Seaweed Script\", cursive"},
    body2: {fontFamily: "\"Seaweed Script\", cursive"}
  }
}

export {
  palette,
  themeOptions
}