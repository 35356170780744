import React, {useEffect, useState} from 'react';
import {Card, CardContent} from '@mui/material';
import {Conversation} from "@twilio/conversations";
import {MessagesViewCreator} from "../../view-creator/MessagesViewCreator";
import {useCookies} from "react-cookie";
import messageViewModel, {MessageView} from "../../views/MessageView";
import {useTwilioClient} from "../../TwilioClientContext";
import logger from "../../utils/logger";
import {ChatConversation} from "../ChatConversation";
import {palette} from "../../palette";
import {DebugPanel} from "../DebugPanel";
import {CohortUserProfile, CohortUserProfileStore} from "../../api/CohortUserProfileStore";
import {PushNotificationService} from "../../api/PushNotificationService";

interface MessageThreadProps {
  conversationId: string,
  groupId: number,
  groupName: string,
}

export const MessageThread: React.FC<MessageThreadProps> = ({conversationId, groupId, groupName}) => {
  const [cookies] = useCookies([
    'auth',
    'profile'
  ]);
  const [convo, setConvo] = useState<Conversation | null>(null);
  const [view, setView] = useState<MessageView[]>([messageViewModel.Empty]);
  const [newMsg, setNewMsg] = useState<string>('');
  const {client, loading} = useTwilioClient();
  const [reload, setReload] = useState<boolean>(true);
  const [profiles, setProfiles] = useState<CohortUserProfile[]>([]);
  const [profilesLoaded, setProfilesLoaded] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState<number>(0);
  // Track changes in loading state
  useEffect(() => {
    logger.debug('loading state changed:', loading);
  }, [loading]);

  useEffect(() => {
    if (groupId > 0) {
      new CohortUserProfileStore().readByGroupId(groupId)
                                  .then(result => {
                                    if (result.status === 'SUCCESS') {
                                      setProfiles(result.result);
                                      setProfilesLoaded(true);
                                    }
                                  })
    }
  }, [groupId]);
  // Effect to handle state changes and trigger reloading conversation
  useEffect(() => {
    logger.debug('useEffect triggered:', {reload, loading, clientReady: !loading && Boolean(client)});

    if (loading) {
      logger.debug('still loading the client...setting reload to false');
      setReload(false);
      return;
    }

    const loadConversation = async () => {
      try {
        if (!client) {
          logger.debug('Client is not ready yet');
          return;
        }
        logger.debug('Attempting to load conversation with ID:', conversationId);
        let conversation: Conversation;

        conversation = await client.getConversationBySid(conversationId);

        client.removeAllListeners('messageAdded')
        conversation.removeAllListeners('messageAdded')

        conversation.on('messageAdded', async (msg) => {
          setReload(true)
          const unread = await conversation.getUnreadMessagesCount().catch(logger.error);
          const num = unread ? Number(unread) : 0;
          setUnreadMessages(num)
        })
        logger.debug('Conversation loaded:', conversation);
        setConvo(conversation);

        try {
          logger.debug('conversation.status', conversation.status)
          logger.debug('conversation.state', conversation.state)

          logger.debug('getting messages...')
          const messages = await conversation.getMessages();
          logger.debug('Messages loaded:', messages.items);
          const view = new MessagesViewCreator().createViewWithArg({
            items: messages.items,
            email: cookies.profile.email
          })
          if (profilesLoaded) {
            const viewWithAvatar = view.map(item => {
              const found = profiles.find(p => item.author === p.email)
              return {...item, avatarUrl: found?.avatarUrl || item.author[0]}
            })
            setView([...viewWithAvatar])
          } else {
            setView([...view]);
          }
        } catch (messageError) {
          logger.error("Error loading messages:", messageError);
        }
      } catch (error) {
        logger.error("Error loading conversation or messages:", error);
      } finally {
        logger.debug('Setting reload to false after loading conversation');
        setReload(false);
      }
    };

    if (!loading) {
      loadConversation();
    }
  }, [
    reload,
    profilesLoaded,
    groupId,
    loading,
    client,
    conversationId,
    cookies.profile.email
  ]);


  // Handle sending a new message
  const handleSend: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    logger.debug('sending message changed...', newMsg);
    logger.debug('convo -->', convo);

    if (!convo) {
      logger.warn('Convo is null, cannot send message');
      return;
    }
    if (newMsg && newMsg.length > 0) {
      const body = newMsg;
      await convo.sendMessage(body);
      await new PushNotificationService().sendNotification({groupId: groupId, message: `A new group message was sent to https://wheapp.com/groups/${groupId}`, groupName: groupName, title: 'New We Hate Exercise Message', userId: cookies.profile.userId})
      setNewMsg('');
      setReload(true); // Reload messages after sending a new oneyarn s
    }
  };

  const handleMessageChange = (e: any) => {
    logger.debug('message changed...', e.currentTarget.value);
    setNewMsg(e.currentTarget.value);
  };

  return (
          <Card variant={"elevation"} sx={{
            border: '1px solid',

            borderColor: palette.primary.main,
            borderRadius: 4
          }}>
            <DebugPanel displayItem={view}></DebugPanel>
            <CardContent sx={{height: 344, display: 'flex', flexDirection: 'column', gap: 2}}>
              <ChatConversation title={"Team Chat"} unreadMessages={unreadMessages} newMsg={newMsg} view={view} onSend={handleSend}
                                onMessageChange={handleMessageChange}/>
            </CardContent>
          </Card>
  );
};


