import {Elements, PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import React, {Fragment, useEffect, useState} from "react";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2 as Grid,
  Typography
} from '@mui/material';
import {grey} from '@mui/material/colors'
import {loadStripe, StripeElementsOptions, StripePaymentElementOptions} from "@stripe/stripe-js";
import {palette} from '../../palette';
import {Form} from "react-router-dom";
import {PaymentsStore} from "../../api/PaymentsStore";
import ApiResponse from "../../api/ApiResponse";


export interface PaymentFormProps {
  cohortUserId: number,
  groupId: number
}

const PaymentForm: React.FC<PaymentFormProps> = ({cohortUserId, groupId}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState<string | undefined>();
  const handleSubmit = async (event: any) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const card = elements.getElement(PaymentElement);

    if (!(card)) {
      return;
    }

    // Use your card Element with other Stripe.js APIs
    const {error} = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: process.env.REACT_APP_BASE_SERVER_URL + `/api/payments/success/cohortUser/${cohortUserId}/group/${groupId}`
      }
    })
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message)
    }
  };
  const paymentElementsOptions = {
    layout: 'auto',
    terms: {card: 'always'},
    paymentMethods: [
      {
        type: 'card',
      }
    ]
  } as StripePaymentElementOptions
  return (
          <Fragment>
            <Form onSubmit={handleSubmit}>
              <Grid display={"flex"} flexDirection={"column"} gap={2} container>
                <PaymentElement options={paymentElementsOptions}></PaymentElement>
                <Grid alignSelf={"flex-end"}>
                  <Button size={"large"} variant={"contained"} type={"submit"}>Submit</Button>
                </Grid>
              </Grid>
            </Form>
            {message &&
                    <Alert severity={"error"}>{message}</Alert>}
          </Fragment>
  )
}
const key = (process.env.NODE_ENV === 'development') ?
        'pk_test_51Pbrh3DgtCzu59hnfhdE0oZhAYSmgqtcTxiSaBs19JZQO0A4Kvy9ksqVoo2jha0C9U15B2LnwTMQBZ4pxhmxw7tU00NRBHZWt9' :
        'pk_live_51Pbrh3DgtCzu59hnYGBBDwEkcVheMoHxk953yBthZ1xXZTrgoFmXbtl6x5y03D8pMyK6tyFkNSqwUPcQ9gx00rVz00U0zDBFxc';

const stripePromise = loadStripe(key)

export interface PaymentModalProps {
  open: boolean,
  amount: number,
  cohortUserId: number,
  groupId: number
}

export const PaymentModal: React.FC<PaymentModalProps> = ({open, amount, groupId, cohortUserId}) => {

  const [clientSecret, setClientSecret] = useState<string>("");

  useEffect(() => {
    ApiResponse.handleApiResponse(() => new PaymentsStore().getToken(amount))
               .then(secret => {
                 setClientSecret(secret)
               })
  }, []);

  const options = {
    clientSecret,
    appearance: {
      theme: 'flat',
      labels: 'floating',
      variables: {
        borderRadius: '4px',
        focusOutline: palette.primary.main,
        colorPrimary: palette.primary.main,
        colorTextPlaceholder: grey[500],
      }
    }
  } as StripeElementsOptions

  return (clientSecret &&
          <Dialog open={open} fullWidth>
            <DialogTitle>Payment Due</DialogTitle>
            <DialogContent>
              <Grid container gap={2} spacing={2} display={"flex"}>
                <Grid>
                  <Typography>The people have spoken! The pledge amount
                    is {Intl.NumberFormat('en-US', {currency: 'USD', style: "currency"})
                            .format(amount)} per person. To continue, you must fund your pledge.
                    Remember, if you meet your goal all 12 weeks, the full amount of your pledge will go directly back
                    to you.</Typography>
                </Grid>
                <Grid width={'100%'}>
                  <Elements options={options} stripe={stripePromise}>
                    <PaymentForm cohortUserId={cohortUserId} groupId={groupId}/>
                  </Elements>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>

            </DialogActions>
          </Dialog>
  )
}