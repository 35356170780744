import {ApiService} from "./ApiService";
import apiResponse  from "./ApiResponse";

export class SubscriptionStore {
  constructor(private readonly api: ApiService = new ApiService()) {
  }

  public async subscribe(subscription: PushSubscription) {
    try {
      const response = await this.api.post('/subscription', {subscription})
      return apiResponse.Success<string>(response)
    } catch (e) {
      return apiResponse.HandleError(e)
    }
  }
}