import {marked}          from "marked";
import {ContentStore}    from "../api/ContentStore";
import {DateTime}        from "luxon";
import {GroupDateMapper} from "../utils/GroupDateMapper";

export class InfoPanelViewCreator {
  constructor(private readonly contentStore: ContentStore = new ContentStore()) {
  }

  public async createView(cohortStart: DateTime) {

    const infoContent = await this.contentStore.readByContentType('info-panel', (item) => {
      return {
        title: item.fields['title'],
        content: marked(item.fields['content']),
        offset: item.fields['offset'],
      }
    })


    const dateMapper = new GroupDateMapper(cohortStart.toJSDate())
    const currentOffset = dateMapper.day(DateTime.local()
                                                 .toISODate())


    const info =
      infoContent.find((item) => item.offset === currentOffset)
    let dismissed = false;
    if (info) {
      dismissed = Boolean(localStorage.getItem(`dashboard:info:${info.title}`))
    }

    return {info, dismissed}

  }
}