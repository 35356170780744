import React, { useEffect, Fragment } from 'react';
import { useCookies } from 'react-cookie';
import { Navigate } from 'react-router-dom';

export const AuthGuard: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [cookies] = useCookies(['auth']);
  const authenticated = Boolean(cookies.auth);

  useEffect(() => {
    // Check if the user is authenticated on each render
    if (!authenticated) {
      // Trigger navigation to login when authentication fails
      window.location.href = '/';
    }
  }, [authenticated]);

  return authenticated ? <Fragment>{children}</Fragment> : null;
};