import {ViewCreator} from './ViewCreator';
import messageViewModel, {MessageView} from '../views/MessageView';
import {Message} from '@twilio/conversations';
import {ToDateTime} from "../utils/DateUtils";
import {DateTime} from "luxon";

interface MessageViewArgs {
  items?: Message[] | null | undefined,
  item?: Message | null | undefined,
  email: string,
}

export class MessageViewCreator extends ViewCreator<MessageView, MessageViewArgs> {
  createView: () => MessageView;
  createViewWithArg: (arg: MessageViewArgs) => MessageView;

  constructor() {
    super();


    this.createView = () => {
      throw new Error('method not implemented')
    }

    this.createViewWithArg = ({item, email}: MessageViewArgs): MessageView => {

      if (item) {
        return {
          date: ToDateTime(item.dateCreated || DateTime.now()),
          myMessage: item.author === email,
          author: item.author,
          text: item.body,
          id: item.sid,
          avatarUrl: ''
        } as MessageView
      }
      return messageViewModel.Empty

    }

  }
}

export class MessagesViewCreator extends ViewCreator<MessageView[], MessageViewArgs> {
  createView: () => MessageView[];
  createViewWithArg: (arg: MessageViewArgs) => MessageView[];

  constructor() {
    super();
    this.createView = () => {
      throw new Error('method not implemented')
    }

    this.createViewWithArg = ({items, email}: MessageViewArgs): MessageView[] => {
      const viewCreator = new MessageViewCreator();
      return items?.map((m: any) => {
        return viewCreator.createViewWithArg({item: m, email: email, items: null})
      }) ?? [messageViewModel.Empty]
    }
  }
}




