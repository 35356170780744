import {GroupView} from "./GroupView";

const empty: GroupsView = {
  groups: [] as GroupView[],
  selectedGroup: {} as GroupView,
  groupsUpdated: false,
  newGroup: {} as GroupView,
  editGroupModalVisible: false,
  newGroupModalVisible: false,
  participantsDialogVisible: false
}

export interface GroupsView {
  groups: GroupView[]
  selectedGroup: GroupView
  groupsUpdated: false,
  newGroup: GroupView,
  editGroupModalVisible: false,
  newGroupModalVisible: false,
  participantsDialogVisible: false
}

const defaultExport = {Empty: empty}
export default defaultExport;