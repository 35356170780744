import {ViewCreator} from "./ViewCreator";
import React, {Fragment} from "react";
import {Typography} from "@mui/material";
import {AcceptInviteView} from "../views/AcceptInviteView";
import {InviteCodeValidator} from "../validators/InviteCodeValidator";


export interface AcceptInviteArgs {
  inviteCode: string | null,
  acceptTerms?: boolean
}

export class AcceptInviteViewCreator extends ViewCreator<AcceptInviteView, AcceptInviteArgs> {
  createView: () => AcceptInviteView;
  createViewWithArg: (arg: { inviteCode: string | null; acceptTerms?: boolean }) => AcceptInviteView;

  constructor() {
    super();
    this.createView = () => {
      throw new Error('Method not implemented')
    }

    this.createViewWithArg = ({inviteCode, acceptTerms}) => {

      const validationErrors = new InviteCodeValidator().validate({inviteCode: inviteCode})
      if (validationErrors.inviteCode) {
        return {
          termsAccepted: acceptTerms ?? false,
          loginEnabled: false,
          validationErrors: validationErrors,
          authRedirectUrl: (process.env.REACT_APP_BASE_SERVER_URL || `http://localhost:3000`) + `/auth?state=${inviteCode}`,
          loginButtonHelp: (<Fragment>
            <Typography color={"inherit"}>
              {validationErrors.inviteCode}
            </Typography>
          </Fragment>)
        }
      }

      if (!acceptTerms) {
        return {
          termsAccepted: acceptTerms ?? false,
          loginEnabled: acceptTerms ?? false,
          authRedirectUrl: (process.env.REACT_APP_BASE_SERVER_URL || `http://localhost:3000`) +`/auth?state=${inviteCode}`,
          loginButtonHelp: (<Fragment>
            <Typography color={"inherit"}>
              You must agree to the terms and conditions before accepting the invitation.
            </Typography>
          </Fragment>)
        }
      }
      return {
        termsAccepted: acceptTerms ?? false,
        loginEnabled: acceptTerms ?? false,
        authRedirectUrl: (process.env.REACT_APP_API_SERVER_URL || 'http://localhost:3000') + `/auth?state=${inviteCode}`,
        loginButtonHelp: ''
      }
    }
  }
}

const viewCreator = new AcceptInviteViewCreator()
const defaultExport = {ViewCreator: viewCreator}
export default defaultExport