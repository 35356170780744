import {
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@mui/material";
import React, {Fragment, useEffect} from "react";
import {useUiState} from "../utils/UiStateHook";
import {palette} from "../palette";
import {DateTime} from "luxon";
import {
  ExerciseContent,
  ExerciseInDisguiseContentViewCreator
} from "../view-creator/ExerciseInDisguiseContentViewCreator";
import {ToDateTime} from "../utils/DateUtils";

interface ExerciseInDisguiseProps {
  enabled?: boolean;
  disabledMessage: string;
  triggerReload: (reload: boolean) => void;
  groupStart: DateTime
}

export const ExerciseInDisguise: React.FC<ExerciseInDisguiseProps> = ({enabled = false, disabledMessage, triggerReload, groupStart}) => {
  const uiState = useUiState(enabled);
  const [content, setContent] = React.useState<ExerciseContent>({} as ExerciseContent)
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    new ExerciseInDisguiseContentViewCreator().CreateView(ToDateTime(groupStart))
                                              .then((content) => setContent(content))
  }, [groupStart]);
  return (
          <Fragment>
            <Card variant={"elevation"} sx={{
              borderRadius: 4,
              border: '1px solid',
              borderColor: uiState.borderColor,
              // backgroundSize: '100% 100%',
              backgroundPosition: 'right',
              backgroundRepeat: 'no-repeat',
              backgroundImage: `url(${enabled ?
                      '//images.ctfassets.net/ihnuhnmumsts/2BKUHRdPz9aJN9GLGNOlaM/5165ad1bb7f801df7f6b3cc041478b54/Untitled_design__10_.png?fm=webp&h=200' :
                      '//images.ctfassets.net/ihnuhnmumsts/4wMbqaJny1jkMtJjzr9iEl/9f36c74e8bfce182d1f822a50c5f5ca6/ExerciseInDisguise_disabled.png?h=200'})`,
              height: 300
            }}>
              <CardContent>
                <Typography variant="h5" component="div" color={enabled ? "primary" : 'textDisabled'}>Exercise In
                  Disguise</Typography>
                {!enabled &&
                        <Typography color={"primary"}
                                    sx={{marginTop: '10%', textShadow: `1px 1px 2px ${palette.background.paper}`, textAlign: 'center', backdropFilter: uiState.backdropFilter}}
                                    variant="h5" component="p"
                                    gutterBottom>{disabledMessage}</Typography>}

              </CardContent>
              <CardContent>
                {enabled && <Typography variant="h3" component="div"
                                        color={"primary"}>{content.content?.title}</Typography>}
              </CardContent>
              {enabled && <CardActions>
                <Button disabled={!enabled && content.content} variant="contained" color="primary"
                        onClick={() => setOpen(true)}>More</Button>
              </CardActions>}
            </Card>
            <Dialog open={open} onClose={() => setOpen(false)}>
              <DialogTitle>{content.content?.title || ''}</DialogTitle>
              <DialogContent>
                <Typography dangerouslySetInnerHTML={{__html: content.content?.body || ''}}></Typography>
              </DialogContent>
              <DialogActions>
                <Button variant={"contained"} onClick={() => setOpen(false)}>Got It!</Button>
              </DialogActions>
            </Dialog>
          </Fragment>
  )
}
