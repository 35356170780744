import React from "react";
import {ParticipantView} from "../../views/ParticipantView";
import {Box, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography} from "@mui/material";
import {palette} from "../../palette";
import Avatar from "@mui/material/Avatar";

interface ParticipantListProps {
  length: number;
  participants: ParticipantView[];
}

export const ParticipantList: React.FC<ParticipantListProps> = ({length, participants}) => {
  return (<Box borderRadius={8} border={1} borderColor={palette.background.border} display={"block"}
               position={"relative"}>
    <Typography variant={"subtitle1"}
                sx={{backgroundColor: palette.background.paper, position: "absolute", top: -32, left: 48, padding: 2}}>
      Participants ({length})
    </Typography>
    <List>
      {participants.map(p => {
        return (
          <ListItem key={p.email}>
            <ListItemAvatar>
              <Avatar sx={{backgroundColor: palette.primary.main}}>{p.email[0]}</Avatar>
            </ListItemAvatar>
            <ListItemText>{p.email}</ListItemText>

            <ListItemSecondaryAction>
              {p.role}
            </ListItemSecondaryAction>
          </ListItem>
        )
      })}
    </List>
  </Box>)
}