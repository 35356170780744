import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid2 as Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  TextField
} from "@mui/material";
import {Delete, MoreVert} from "@mui/icons-material";
import * as React from "react";
import {Fragment, PropsWithChildren, useState} from "react";
import {GroupSurveyConfigurationModal} from "../modals/GroupSurveyConfigurationModal";
import {GroupView} from "../../views/GroupView";
import logger from "../../utils/logger";
import {DebugPanel} from "../DebugPanel";
import {UpdateGroupAction} from "../../actions/Group/UpdateGroupAction";
import {NoParamCallback} from "node:fs";
import {useTwilioClient} from "../../TwilioClientContext";
import {useCookies} from "react-cookie";

interface GroupActionCellProps extends PropsWithChildren {
  group: GroupView,
  updateSummary: NoParamCallback
}

export const GroupActionCell: React.FC<GroupActionCellProps> = ({updateSummary, group}) => {
  const [configureSurveyDialogOpen, setConfigureSurveyDialogOpen] = useState<boolean>(false);
  const [showGroupMessage, setShowGroupMessage] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const moreMenuOpen = Boolean(anchorEl);
  const [configurePledgeDialogOpen, setConfigurePledgeDialogOpen] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<GroupView>(group);
  const [groupMessage, setGroupMessage] = useState<string>('')
  const handleCloseConfigureSurveyDialogModal = () => {
    setConfigureSurveyDialogOpen(false)
  }
  const handleSetPledge = (e: any) => {
    setAnchorEl(null)
    setConfigurePledgeDialogOpen(true)
  }
  const handleSendMessage = (e: any) => {
    setAnchorEl(null)
    setShowGroupMessage(true)
  }
  const savePledgeAmount = async () => {
    await new UpdateGroupAction().Execute(selectedGroup, 'SAVE')
    setConfigurePledgeDialogOpen(false)
    updateSummary(null)
  }
  const handlePledgeChange = (e: any) => {
    setSelectedGroup({...selectedGroup, pledge: e.target.value})
  }
  const {client, loading} = useTwilioClient()
  const [cookies] = useCookies(['profile'])
  return (<Grid container flexDirection={"row"}>
            <IconButton aria-label="delete" role={"button"} onClick={() => logger.debug('delete the row')}
                        size={"small"}>
              <Delete></Delete>
            </IconButton>
            <Fragment>
              <IconButton aria-label="more" role={"button"} onClick={(e) => setAnchorEl(e.currentTarget)}
                          size={"small"}>
                <MoreVert></MoreVert>
              </IconButton>
              <Menu open={moreMenuOpen} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
                <MenuItem id={"SetPledgeAmount"} key={'pledge'} onClick={handleSetPledge}>Set Pledge Amount</MenuItem>
                <MenuItem id={"ParticiapntGoals"} key={'participantGoals'} onClick={() => {}}>View Participants Goals</MenuItem>
                {selectedGroup.chatSid &&
                        <MenuItem id={"SendGroupMessage"} key={'message'} onClick={handleSendMessage}>Send Message to
                          Group</MenuItem>}
              </Menu>

              <GroupSurveyConfigurationModal key={`${group.id}${group.name}`}
                                             onClose={handleCloseConfigureSurveyDialogModal}
                                             group={group}
                                             open={configureSurveyDialogOpen}></GroupSurveyConfigurationModal>

              <Dialog open={configurePledgeDialogOpen} onClose={() => setConfigurePledgeDialogOpen(false)}>
                <DebugPanel displayItem={selectedGroup}></DebugPanel>
                <DialogTitle>Set Pledge Amount</DialogTitle>
                <DialogContent style={{paddingTop: 4}}>
                  <FormControl>
                    <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                    <OutlinedInput
                            onChange={handlePledgeChange}
                            id="outlined-adornment-amount"
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            label="Amount"
                            value={selectedGroup.pledge}
                    />
                  </FormControl>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setConfigurePledgeDialogOpen(false)}>Close</Button>
                  <Button variant={"contained"} onClick={savePledgeAmount}>Save</Button>
                </DialogActions>
              </Dialog>
              <Dialog open={showGroupMessage} fullWidth onClose={() => setConfigurePledgeDialogOpen(false)}>
                <DebugPanel displayItem={selectedGroup}></DebugPanel>
                <DialogTitle>Send Group Message</DialogTitle>
                <DialogContent style={{paddingTop: 4}}>
                  <TextField minRows={7} multiline={true} fullWidth
                             onChange={(e: any) => setGroupMessage(e.currentTarget.value)}>{groupMessage}</TextField>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setShowGroupMessage(false)}>Close</Button>
                  <Button disabled={loading} variant={"contained"} onClick={async () => {
                    const convo = await client!.getConversationBySid(selectedGroup.chatSid)
                    if (convo) {
                      const participants = await convo.getParticipants()
                      if (!participants.some(p => p.identity === cookies.profile.email)) {
                        await convo.join()
                      }
                      await convo.sendMessage(groupMessage)
                      setGroupMessage('')
                      setShowGroupMessage(false)
                    }
                  }}>Send</Button>
                </DialogActions>
              </Dialog>
              <Dialog open={false}>
                <DialogTitle></DialogTitle>
                <DialogContent></DialogContent>
                <DialogActions>
                  <Button></Button>
                </DialogActions>
              </Dialog>
            </Fragment>
          </Grid>
  )
};