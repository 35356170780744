import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Typography} from "@mui/material";

export interface InfoDialogProps extends DialogProps {
  message: string,
  title: string,
  onDismiss: () => void,
  onReadLater: () => void,
}

export const InfoDialog: React.FC<InfoDialogProps> = ({open, message, title, onClose, onDismiss, onReadLater}) => {

  return (
          <Dialog open={open} onClose={onClose} fullWidth={true}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
              <Typography component={"div"} dangerouslySetInnerHTML={{__html: message}}></Typography>
            </DialogContent>
            <DialogActions>
              <Button variant={"contained"} onClick={onReadLater}>I'll read it later</Button>
              <Button onClick={onDismiss}>Got It!</Button>
            </DialogActions>
          </Dialog>
  )
}