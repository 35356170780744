import {
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@mui/material";
import React, {Fragment, useEffect} from "react";
import {useUiState} from "../utils/UiStateHook";
import {palette} from "../palette";
import {DateTime} from "luxon";

import logger from "../utils/logger";
import {GetScienceSomethingViewCreator, ScienceSomethingContent} from "../view-creator/GetScienceSomethingViewCreator";
import {ToDateTime} from "../utils/DateUtils";

interface ScienceSomethingProps {
  enabled?: boolean;
  disabledMessage: string;
  triggerReload: (reload: boolean) => void;
  startDate: DateTime
}

export const ScienceSomethingCard: React.FC<ScienceSomethingProps> = ({enabled = false, disabledMessage, triggerReload, startDate}) => {
  const uiState = useUiState(enabled);
  const [open, setOpen] = React.useState(false);
  const [content, setContent] = React.useState<ScienceSomethingContent>({} as ScienceSomethingContent);
  useEffect(() => {
    new GetScienceSomethingViewCreator().CreateView(ToDateTime(startDate))
                                        .then(setContent)
                                        .catch(err => logger.error(err));
  }, [startDate]);
  return (
          <Fragment>
            <Card variant={"elevation"} sx={{
              // backgroundColor: uiState.backgroundColor,

              borderRadius: 4,
              border: '1px solid',
              borderColor: uiState.borderColor,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right',
              backgroundImage: `url(${enabled ? '//images.ctfassets.net/ihnuhnmumsts/4Kr48dEQz6a4hAedlFhGGO/fe097483a924f4a17a7a59114750b043/Untitled_design__6_.png?h=200' : '//images.ctfassets.net/ihnuhnmumsts/4KYnwIItblCUlel8KDd43r/64577936e71ef3d28ee8bb84a4bb19c2/Untitled_design__6_disabled.png?h=200'})`,
              height: 300
            }}>
              <CardContent>
                <Typography variant="h5"
                            component="div"
                            color={enabled ? "primary" : "textDisabled"}>Science
                  Something</Typography>

              </CardContent>
              <CardContent>
                {!enabled &&

                        <Typography color={"primary"}
                                    sx={{marginTop: '10%', textShadow: `1px 1px 2px ${palette.background.paper}`, textAlign: 'center', backdropFilter: uiState.backdropFilter}}
                                    variant="h5" component="p"
                                    gutterBottom>{disabledMessage}</Typography>
                }
              </CardContent>
              <CardContent sx={{margin: 0, padding: '0 6rem'}}>
                {enabled && <Typography variant="body2" sx={{fontSize: 24, textAlign: 'center'}} component="div"
                                        color={"primary"}>{content.content?.title}</Typography>}

              </CardContent>
              <CardActions></CardActions>
              {enabled && <CardActions
                      sx={{alignItems: "baseline", display: "flex", justifyContent: "flex-end", flexDirection: "column",}}>
                <Button disabled={!enabled && content.content} variant="contained" color="primary"
                        onClick={() => setOpen(true)}>More</Button>
              </CardActions>}
            </Card>
            <Dialog open={open}>
              <DialogTitle>
                {content.content?.title}
              </DialogTitle>
              <DialogContent>{content.content?.body}</DialogContent>
              <DialogActions>
                <Button variant={"contained"} onClick={() => setOpen(false)}>Got It!</Button>
              </DialogActions>
            </Dialog>
          </Fragment>
  )
}


