import {Group}      from "../models";
import {ApiService} from './ApiService'
import apiResponse  from "./ApiResponse";
import {GroupView}  from "../views/GroupView";

export class GroupStore {
  constructor(private readonly api: ApiService = new ApiService()) {
  }

  public async ReadOne(groupId: number) {
    try {
      const result = await this.api.get(`Cohort/${groupId}`)
      return apiResponse.Success<Group>(result)
    } catch (e) {
      return apiResponse.HandleError(e);
    }

  }

  public async ReadAll() {
    try {
      const response = await this.api.get('Cohort')
      // await sleep(2000);
      return apiResponse.Success(response);
    } catch (e) {
      return apiResponse.HandleError(e);
    }
  }

  public async Write(selectedGroup: GroupView) {
    try {
      const response = await this.api.post('cohort', selectedGroup)
      return apiResponse.Success(response);
    } catch (e) {
      return apiResponse.HandleError(e);
    }
  }

  public async Update(group: Group) {
    try {
      const response = await this.api.put('cohort', group)
      return apiResponse.Success(response);
    } catch (e) {
      return apiResponse.HandleError(e);
    }
  }

  public async Delete(group: Group) {
    try {
      const response = await this.api.delete(`/cohort/${group.id}`)
      return apiResponse.Success(response);
    } catch (e) {
      return apiResponse.HandleError(e);
    }
  }
}