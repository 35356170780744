import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import * as twilio from '@twilio/conversations'

export interface MessageState {
  twilioClient: twilio.Client | null
}

const initialState: MessageState = {
  twilioClient: null
}

const messageSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    receivedClient(state, action: PayloadAction<twilio.Client>) {

      // @ts-ignore
      state.twilioClient = action.payload;
    }
  }
});

export const {receivedClient} = messageSlice.actions
export default messageSlice.reducer;