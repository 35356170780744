import {RecordActivityView} from "../views/RecordActivityView";
import {ApiService}         from "./ApiService";
import apiResponse          from "./ApiResponse";

export class ActivityTrackingStore {
  constructor(private readonly api: ApiService = new ApiService()) {
  }

  public async write(activityView: RecordActivityView) {
    try {
      const response = await this.api.post('/activitytracking', activityView)
      return apiResponse.Success(response)
    } catch (e) {
      return apiResponse.HandleError(e)
    }
  }

  public async readAllByCohortUser(cohortUserId: number) {
    try {
      const response = await this.api.get(`/cohortUser/${cohortUserId}/activityTracking`)
      return apiResponse.Success<ActivityTrackingItem[]>(response)
    } catch (e) {
      return apiResponse.HandleError(e)
    }
  }
}

export type ActivityTrackingItem = {
  id: number;
  activityId: number;
  timeframeId: number
  cohortUserId: number;
  dateStamp: Date;
  duration: number;
  name: string;
}