import {MessageView} from "../views/MessageView";
import React, {FC, Fragment, useEffect, useRef, useState} from "react";
import {Avatar, Badge, Box, Grid2 as Grid, IconButton, Skeleton, TextField, Tooltip, Typography} from "@mui/material";

import {Send} from "@mui/icons-material";
import {palette} from "../palette";

interface ChatConversationProps {
  view: MessageView[],
  onSend: (e: any) => void,
  newMsg: string
  onMessageChange: (e: any) => void,
  title?: string,
  autoScroll?: boolean,
  unreadMessages: number
}

export const ChatConversation: FC<ChatConversationProps> = ({autoScroll = true, title, newMsg, view, onSend, onMessageChange, unreadMessages}) => {
  const messagesEndRef = useRef<any>()
  const [badgeHidden, setBadgeHidden] = useState<boolean>(true)
  useEffect(() => {
    setBadgeHidden(true)
    if (unreadMessages)
      setBadgeHidden(false)

  }, [unreadMessages]);
  useEffect(() => {
    if (messagesEndRef.current)
      messagesEndRef.current?.scrollIntoView({behavior: "smooth"});
  }, [view]);
  return (
          <Fragment>
            <Badge anchorOrigin={{horizontal: 'left', vertical: 'top'}}
                   badgeContent={unreadMessages}
                   color="primary">
              <Typography variant="h5" component="div" color="primary">
                {title}
              </Typography>
            </Badge>
            <Box display={"flex"} flexDirection={"column"} gap={2} overflow={"scroll"}>
              {view.length < 1 &&
                      <Fragment>
                        <Box display={"flex"} flexDirection={"row"} justifyContent={"end"}>
                          <Skeleton>
                            <Typography variant={"caption"}></Typography>
                          </Skeleton>
                        </Box>
                        <Grid display={'flex'} flex={'1 1 100%'} flexDirection={"row"} gap={1}
                              alignItems={"center"}>
                          <Skeleton variant={"circular"}>
                            <Avatar variant={"circular"} sx={{height: 32, width: 32}}>
                              <Typography variant={"subtitle1"} component={"span"}></Typography>
                            </Avatar>
                          </Skeleton>
                          <Box borderRadius={8} width={'100%'} padding={2}
                               sx={{backgroundColor: palette.primary.light50}}
                               display={"flex"}
                               flexDirection={"column"}>
                            <Skeleton animation={"wave"}>
                              <Typography variant={"body1"} align={"right"}></Typography>
                            </Skeleton>
                          </Box>
                        </Grid>
                      </Fragment>
              }
              {view.map((m, idx) => (
                      <Fragment key={`${idx}-${m.id}`}>
                        <Box display={"flex"} flexDirection={"row"} justifyContent={"end"}>
                          <Typography variant={"caption"}>
                            {m.date.toFormat('MMM dd yyyy')}
                          </Typography>
                        </Box>
                        <Box flex={'1 1 100%'} display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"}>
                          <Tooltip title={m.author}>
                            <Avatar src={m.avatarUrl} variant={"circular"}
                                    sx={{backgroundColor: palette.primary.main50, height: 32, width: 32}}>
                              {m.author.toUpperCase()[0]}
                            </Avatar>
                          </Tooltip>
                          {m.myMessage ? (
                                  <Box borderRadius={8} width={'100%'} padding={2}
                                       sx={{backgroundColor: palette.primary.main50}}
                                       display={"flex"}
                                       flexDirection={"column"}>
                                    <Typography color={palette.primary.contrastText} variant={"body1"} align={"right"}>
                                      {m.text}
                                    </Typography>
                                  </Box>
                          ) : (
                                  <Box borderRadius={8} sx={{backgroundColor: palette.primary.light50}} width={'100%'}
                                       padding={2}
                                       display={"flex"}
                                       flexDirection={"column"}>
                                    <Typography color={palette.primary.contrastText} variant={"body1"} align={"left"}>
                                      {m.text}
                                    </Typography>
                                  </Box>
                          )}
                        </Box>
                        {autoScroll && (view.length === idx + 1) &&
                                <div ref={messagesEndRef}></div>}
                      </Fragment>
              ))}

            </Box>
            <Grid sx={{marginTop: 2}} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}
                  alignItems={'center'} gap={2}>
              <TextField multiline={true} sx={{width: '100%'}} label={"Reply"} value={newMsg}
                         onChange={onMessageChange}></TextField>
              <IconButton color={"primary"} onClick={onSend} value={newMsg}>
                <Send/>
              </IconButton>
            </Grid>
          </Fragment>
  )
}